import Home from "./components/Home";
import Layout from "./components/Layout";
import VideoDetail from './components/VideoDetail'
import Authentication from './components/api-authorization/Authentication';
import Register from './components/api-authorization/Register';
import SpeakersYear, { Speakers } from './components/Speakers';
import About from './components/About';
import Agenda from './components/Agenda';
import Exhibition from './components/Exhibition';
import SpeakerDetail from './components/SpeakerDetail';
import Review, { PlayBack } from './components/PlayBack';
import Privacy from './components/api-authorization/Privacy';
import EmailVerify from './components/api-authorization/EmailVerify';
import { defer } from "react-router-dom";


const AppRoutes = [
    {
        path: "",
        element: <Layout />,
        loader: ({ params }) => {
            const myPromise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 2000);
            });
            const result = fetch('/api/Layout').then((response) => response.json()).catch((error) => {
                return false;
            });
            return defer({ timer: myPromise, Result: result });
        },
       
        children: [
            {
                path: "",
                element: <Home />,
                loader: ({ params }) => {
                    const result = fetch('/api/Home/').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })
                }               
            },
            {

                path: "home",
                element: <Home />,
                loader: async ({ params }) => {
                    const result = fetch('/api/Home/').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })


                },
                action: async ({ request }) => {
                    var promise=new Promise((resolveOuter) => {
                        resolveOuter(
                            new Promise((resolveInner) => {
                                setTimeout(resolveInner, 500);
                            }),
                        );
                    });
                    await promise;
                    var formData = await request.formData();
                    const result = await fetch('/api/Home', {
                        method: 'post',
                        body: formData,
                    });
                    return result;
                }
            },
            {
                path: "aboutus",
                element: <About />,
                loader: async ({ params }) => {
                    const result = fetch('/api/About/').then((response) => response.json()).catch((error) => {
                        return false;
                    });

                    return defer({ Result: result })
                }

            },
            {
                path: "agenda",
                element: <Agenda />,
                loader: async ({ params }) => {
                    const result = fetch('/api/Agenda/').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })
                }

            },
            {
                path: "privacy",
                element: <Privacy />,
                loader: async ({ params }) => {
                    const result = fetch('/api/Privacy').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })
                }

            },



            {
                path: "speakers",
                element: <SpeakersYear />,
                loader: ({ params }) => {
                    const result = fetch('/api/Speakers').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })
                },
                children: [
                    {
                        path: ":year",
                        element: <Speakers />,
                        loader: async ({ params }) => {
                            const result = fetch(`/api/Speakers/${params.year}`).then((response) => response.json()).catch((error) => {
                                return false;
                            });
                            return defer({ Result: result });
                        },
                        children: [
                            {
                                path: ":speakerId",
                                element: <SpeakerDetail />,
                                loader: async ({ params }) => {

                                    const result = fetch(`/api/Speakers/${params.year}/${params.speakerId}`).then((response) => { if (response.ok) { return response.json() } else { throw new Error("Not Found 404!"); } }).catch((error) => {
                                        return false;
                                    });
                                    return defer({ Result: result });
                                }
                            }
                        ]

                    }
                ]

            },
            {


                path: 'review',
                element: <Review />,
                loader: ({ params }) => {
                    const result = fetch('/api/Review').then((response) => response.json()).catch((error) => {
                        return false;
                    });
                    return defer({ Result: result })
                },

                children: [
                    {
                        path: ":year",
                        element: <PlayBack />,
                        loader: async ({ params }) => {
                            const result = fetch(`/api/Review/${params.year}`).then((response) => response.json()).catch((error) => {
                                return false;
                            });
                            return defer({ Result: result });
                        }
                    },
                    {
                        path: ":year/:videoId",
                        element: <VideoDetail />,
                        loader: async ({ params }) => {
                            const result = fetch(`/api/Review/${params.year}/${params.videoId}`).then((response) => { if (response.ok) { return response.json() } else { throw new Error("Not Found 404!"); } }).catch((error) => {
                                return false;
                            });
                            return defer({ Result: result });
                        }
                    }
                ]


            },

            {
                path: 'emailverify',
                element: <EmailVerify />,
                loader: async () => {
                    const searchParams = window.location.search;
                    const response = await fetch(`/api/Register/EmailConfirm${searchParams}`).catch((error) => {
                        return false;
                    });
                    
                    return response.ok;
                }
            }

        ]

    },
    

    {
        path: "/authentication",
        element: <Authentication />,
        children: [
            {
                path: "register",
                element: <Register />,
                action: async ({ request, params }) => {

                    const formData = await request.formData();


                    const result = await fetch('/api/register', {
                        method: 'post',
                        body: formData,
                    });

                    return result.ok;

                }
            }
            

        ]

    }


    //...ApiAuthorzationRoutes
];

export default AppRoutes;
