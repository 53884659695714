import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { useTranslation } from 'react-i18next';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export default  function LoginMenu(){

    const { t } = useTranslation();
        return (<>
            {/*
          <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>{t("login")}</NavLink>
            </NavItem>
            */ }
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={ApplicationPaths.Register}>{t("register")}</NavLink>
            </NavItem>

        </>);
    
}


