import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { Transition } from 'react-transition-group';
import classNames from 'classnames';
import { useRef } from 'react';
import { Helmet } from "react-helmet";
import { useReducer } from 'react';
import { useEffect, useState } from "react";
import { Form, Outlet, Link, useActionData, useSubmit, NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import procity from './pc-code.json';
import countries from './countries.json';
import './Authentication.css';
import { Collapse,Container } from 'reactstrap';

export default function Register() {


    const { t, i18n } = useTranslation();

    const [cities, setCities] = useState([t('Please Select...')]);
    const [provinces, setProvince] = useState(procity);

    const [isCountrySelected, setCountrySelect] = useState(true);
    const [isProvinceSelected, setProvinceSelect] = useState(false);
    const [isCountryCodeSelected, setCountryCodeSelectd] = useState(true);
    const [isCampusSelected, setCampusSelected] = useState(false);
    const [isChinaSelected, setChinaSelected] = useState(true);
    const [isSubmitting, setSubmitting] = useState(false);
    const [interests, setInterests] = useState([]);
    const [isEmailValid, setisEmailValid] = useState(true);
    const formRef = useRef();
    const interestRef = useRef();
    const duration = 600;

 
    const [nameClasses, setnameClasses] = useState('form-control');
    const [emailClasses, setemailClasses] = useState('form-control');
    const [phoneClasses, setphoneClasses] = useState('form-control');
    const [countryClasses, setcountryClasses] = useState('form-control');
    const [institutionClasses, setinstitutionClasses] = useState('form-control');
    const [interestClasses, setinterestClasses] = useState('form-control');
    const [privacyClasses, setprivacyClasses] = useState('form-check-input');
    const [idClasses, setidClasses] = useState('form-control');
    const [provinceClasses, setprovinceClasses] = useState('form-control');
    const [cityClasses, setcityClasses] = useState('form-control');

    const [formData, setFormData] = useState();
    const submit = useSubmit();

    if (!i18n.language.startsWith('en')) {
        countries = countries.sort((a, b) => a.translations.cn.localeCompare(b.translations.cn, 'zh'))
    }


    useEffect(() => {
        if (formData) {

            let canSubmit = true;

            const nclasses = classNames(
                'form-control',
                formData.get('name') ? "" : "is-invalid"
            );

            if (nclasses.includes("is-invalid")) {
                canSubmit = false;
            }

            setnameClasses(nclasses);



            const eclasses = classNames(
                'form-control',
                formData.get('email') ? (isEmailValid ? '' : 'invalid_email') : 'is-invalid'
            )
            if (eclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setemailClasses(eclasses);


            const pclasses = classNames(
                'form-control',
                formData.get('phone') ? '' : 'is-invalid'
            )
            if (pclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setphoneClasses(pclasses);


            const cclasses = classNames(
                'form-control',
                formData.get('country') ? '' : 'is-invalid'
            )
            if (cclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setcountryClasses(cclasses);


            if (isChinaSelected) {
                const provinceclasses = classNames(
                    'form-control',
                    formData.get('province') ? '' : 'is-invalid'
                )
                if (provinceclasses.includes("is-invalid")) {
                    canSubmit = false;
                }
                setprovinceClasses(provinceclasses);


                const cityclasses = classNames(
                    'form-control',
                    formData.get('city') ? '' : 'is-invalid'
                )
                if (cityclasses.includes("is-invalid")) {
                    canSubmit = false;
                }
                setcityClasses(cityclasses);

            }


            const iclasses = classNames(
                'form-control',
                formData.get('institution') ? '' : 'is-invalid'
            )
            if (iclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setinstitutionClasses(iclasses);


            const interclasses = classNames(
                'form-control',
                formData.get('interest') ? '' : 'is-invalid'
            )
            if (interclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setinterestClasses(interclasses);


            if (isCampusSelected) {
                const idclasses = classNames(
                    'form-control',
                    formData.get('id') ? '' : 'is-invalid'
                )
                if (idclasses.includes("is-invalid")) {
                    canSubmit = false;
                }
                setidClasses(idclasses);

            }


            const privacyclasses = classNames(
                'form-check-input',
                formData.get('privacy') ? '' : 'is-invalid'
            );
            if (privacyclasses.includes("is-invalid")) {
                canSubmit = false;
            }
            setprivacyClasses(privacyclasses);


            if (canSubmit) {
                submit(formData, { method: "post", action: "/authentication/register" });
                setSubmitting(true);
            }


            //to do pending style
        }
        return () => {

        };


    }, [formData, setnameClasses, setemailClasses, setphoneClasses, setcountryClasses, setcityClasses, setprovinceClasses, setidClasses, setinstitutionClasses, setprivacyClasses /*nameClasses, emailClasses, phoneClasses, countryClasses, institutionClasses, interestClasses, privacyClasses, idClasses, provinceClasses, cityClasses, formRef*/])









    function onHandleSubmit(event) {
        event.preventDefault();
        let formData = new FormData(formRef.current);
        setFormData(formData);

    }

    function onHandleChangeName(e) {
        setnameClasses('form-control');

    }

    function onHandleChangePhone(e) {
        setphoneClasses('form-control');
    }

    function onHandleChangeEmail(e) {
        setisEmailValid(true);
        setemailClasses('form-control');
    }

    function onHandleEmailBlur(e) {
        const pattern = "^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$"
        const regex = new RegExp(pattern)
        let result = regex.test(e.target.value);
        if (!result) {
            setisEmailValid(false);
        }
        else {
            setisEmailValid(true);
        }
    }



    function onHandleInterestChange(e) {
        if (e.target.checked) {
            setInterests([...interests, e.target.value]);
        }
        else {
            setInterests(interests.filter((interest) => interest !== e.target.value));
        }

        setinterestClasses('form-control');
        /*
        if (e.target.checked) {
            if (!interestRef.current.value) {
                interestRef.current.value = e.target.value;
            }
            else {
                interestRef.current.value = [interestRef.current.value, e.target.value];
            }
        }
        else {
            interestRef.current.value=[interestRef.current.value.split(',').filter((v) => v !== e.target.value)];
        }*/

    }
    function onHandleCampusChange(e) {
        const isCampus = e.target.checked === true ? true : false;
        setCampusSelected(isCampus);
    }


    function onHandleCountryChange(e) {
        setcountryClasses('form-control');
        const value = e.target.value;
        if (!!value) {
            //let cities = procity.find((province) => province.name === value).children;
            let pros;
            if (value === "China Mainland") {
                pros = procity;
                setChinaSelected(true);
                setProvince(pros);
            }
            else {
                setChinaSelected(false);
                // pros = countries.find((country) => country.name === value).states;
            }
        }
        else {
            setCountrySelect(false);
        }

    }



    function onHandleProvinceChange(e) {
        const value = e.target.value;
        setprovinceClasses('form-control');
        //setCities(cities);
        if (!!value) {
            //let cities = procity.find((province) => province.name === value).children;
            const cis = provinces.find((province) => province.name === value).cities;
            setCities(cis);


            setProvinceSelect(true);

        }
        else {
            setProvinceSelect(false);
        }
    }

    function onHandleIDChange(e) {
        setidClasses('form-control');
    }

    function onHandleCityChange(e) {
        setcityClasses('form-control');
    }

    function onHandleInstitutionChange() {
        setinstitutionClasses('form-control');
    }

    function onHandlePrivacyChange() {
        setprivacyClasses('form-check-input');
    }


    let emailaddress;
    const resultResponse = useActionData();
    if (resultResponse) {
        emailaddress = formData.get('email');
        formRef.current.reset();
    }
    return (
        <>
            <Helmet>
                <title>{t("register")}</title>
            </Helmet>
            <div className="authenter">
                <div className="auth_indicator">
                    <p>{t("October 14-15, 2023")}</p>
                    <p>{t("Beijing, China")}</p>
                    {/*
                    <p className="deadline">{t("Registration Deadline: October 10, 2023")}</p>
                    */ }
                </div>
                <div className="form_area">
                    <Form method="post" className="needs-validation" ref={formRef} noValidate onSubmit={onHandleSubmit}>
                        <div className="form-group row">
                            <label htmlFor="name" className="col-sm-2 col-md-2 col-form-label">
                                {t("name")}
                            </label>
                            <div className="col-sm-10 col-md-10">
                                <input name="name" className={nameClasses} id="name" required onChange={onHandleChangeName} />
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {t("enteryourname")}
                                </div>
                            </div>

                        </div>
                        <div className="form-group row">
                            <label htmlFor="email" className="col-sm-2 col-md-2 col-form-label">
                                {t("email")}
                            </label>
                            <div className="col-sm-10 col-md-10">
                                <input name="email" type="email" className={emailClasses} id="email" onBlur={onHandleEmailBlur} required onChange={onHandleChangeEmail} />
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {t("enteryouremail")}
                                </div>
                                {
                                    isEmailValid ? false : (<div className="invalid-email">{t("Your Email Address is Invalid")}</div>)
                                }
                            </div>
                        </div>

                        <div className="form-group row phone">
                            <label htmlFor="phone" className="col-12 col-sm-2 col-form-label">
                                {t("phone")}
                            </label>
                            <div className="col-sm-10 phone_container">
                                <div className="col-4 col-sm-4 col-md-4">
                                    <select className="form-control " name="countryCode" id="countryCode" required defaultValue='86'>
                                        {
                                            countries.map((country) => {
                                                return <option key={country.name} data-code={country.id} value={country.phone_code} >{
                                                    i18n.language.startsWith('en') ? `(+${country.phone_code})${country.name}` : `(+${country.phone_code})${country.translations.cn}`
                                                }</option>
                                            })
                                        }


                                    </select>
                                </div>

                                <div className="col-8 col-sm-8 col-md-8">
                                    <input name="phone" className={phoneClasses} id="phone" required onChange={onHandleChangePhone} />
                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                        {t("enteryourphone")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-group country_row row">

                            <label htmlFor="country" className="col-sm-3 col-md-3 col-lg-3 col-form-label">
                                {t("countryorregion")}
                            </label>
                            <div className="col-sm-9 col-md-9 col-lg-9">
                                <select className={countryClasses} name="country" id="country" defaultValue="China Mainland" onChange={onHandleCountryChange} required >

                                    {
                                        countries.map((country) => {
                                            return <option key={country.id} data-code={country.id} value={country.name} >{
                                                i18n.language.startsWith('en') ? country.name : country.translations.cn
                                            }</option>
                                        })
                                    }

                                </select>
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {t("chooseyourcountry")}
                                </div>
                            </div>

                        </div>
                        <Collapse isOpen={isChinaSelected}>
                            {
                                isChinaSelected ? (
                                    <div className="form-group row region">
                                        <label htmlFor="province" className="col-sm-2 col-md-2 col-form-label">
                                            {t("province")}
                                        </label>
                                        <div className="col-sm-3 col-md-3 ">
                                            <select className={provinceClasses} name="province" id="province" defaultValue="" onChange={onHandleProvinceChange} disabled={!isChinaSelected} required>
                                                <option key="0" data-code="0" value="">{t("pleaseselect")}</option>
                                                {
                                                    provinces.map((provice) => {
                                                        return <option key={provice.name} value={provice.name} >{provice.name}</option>
                                                    })
                                                }

                                            </select>
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                {t("chooseyourprovince")}
                                            </div>
                                        </div>
                                        <label htmlFor="city" className="col-sm-3 col-md-3  col-form-label">
                                            {t("City (District):")}
                                        </label>
                                        <div className="col-sm-4 col-md-4">
                                            <select className={cityClasses} name="city" id="city" defaultValue="" disabled={!isProvinceSelected} onChange={onHandleCityChange} >
                                                <option key="0" data-code="0" value="">{t("pleaseselect")}</option>
                                                {
                                                    cities.length>1 && cities.map((city) => {
                                                        return <option key={city.name} data-code={city.id} value={city.name} >{city.name}</option>
                                                    })
                                                }
                                            </select>
                                            <div id="validationServer05Feedback" className="invalid-feedback">
                                                {t("chooseyourcity")}
                                            </div>
                                        </div>
                                    </div>) : false

                            }

                        </Collapse>
                        <div className="form-group row">
                            <label htmlFor="institution" className="col-sm-2 col-md-2 col-form-label">
                                {t("institution")}
                            </label>
                            <div className="col-sm-10 col-md-10">
                                <input name="institution" className={institutionClasses} id="institution" required onChange={onHandleInstitutionChange} />
                                <div id="validationServer05Feedback" className="invalid-feedback">
                                    {t("enteryourinstitution")}
                                </div>
                            </div>

                        </div>
                        <div className="form-group row interest">
                            <label htmlFor="interest" className="col-sm-3 col-md-3 col-form-label">
                                {t("interest")}
                            </label>
                            <div className="col-sm-9 col-md-9">
                                <input ref={interestRef} name="interest" className={interestClasses} id="interest" type="hidden" value={interests} required />
                                <div className="invalid-feedback">{t("chooseoneitematleast")}</div>
                            </div>
                            <div className="col-sm-12 col-md-12 interest_container" >

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Genome 3D Architecture" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">
                                        {t("Single Cell Genome 3D Architecture")}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Epigenome" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Single Cell Epigenome")}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Atlas" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Single Cell Atlas")}</label>
                                </div>


                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Genomics Technologies（Proteomics and Spatial Omics）" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Single Cell Genomics Technologies（Proteomics and Spatial Omics）")}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Bioinformatics" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Single Cell Bioinformatics")}</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="Single Cell Applications" onChange={onHandleInterestChange} />
                                    <label className="form-check-label" htmlFor="inlineCheckbox1">{t("Single Cell Applications")}</label>
                                </div>
                            </div>
                        </div>

                        <div className="form-check form-switch">
                            <input className="form-check-input" name="isCampus" type="checkbox" id="isCampus" value="false" onChange={onHandleCampusChange} disabled/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("campusvisits")}</label>
                        </div>
                        <Collapse isOpen={isCampusSelected}>

                            <div className="form-group row">
                                <label htmlFor="id" className="col-sm-2 col-md-2 col-form-label">
                                    {t("idtype")}
                                </label>



                                <div className="col-sm-10 col-md-10">
                                    <select className="form-control" name="idType" id="idType" required={isCampusSelected}>
                                        <option key="0" data-code="0" value="Chinese Identity">{t("chineseidentity")}</option>
                                        <option key="1" data-code="1" value="Passport">{t("passport")}</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="id" className="col-sm-2 col-md-2 col-form-label">
                                    {t("id")}
                                </label>

                                <div className="col-sm-10 col-md-10">
                                    <input name="id" className={idClasses} id="id" required={isCampusSelected} onChange={onHandleIDChange} />
                                    <div id="validationServer05Feedback" className="invalid-feedback">
                                        {t("Please Enter Your ID Number or Passport Number")}
                                    </div>
                                </div>


                            </div>
                            <div className="visitor_note alert alert-primary">
                                <p>SCO 2023 opens up to 150 non-PKUer attendants. The appointment process and regulations are as follows:
                                </p><p>
                                    1. Appointments for campus visits must be registered with your legal name and completed by 12:00 on Oct. 6, 2023. After making a reservation, you will have one-time access to PKU campus on Oct. 14 and Oct. 15 respectively. Please bring your ID card (or passport) and enter the campus through the designated entrance at the West Gate and leave the campus before 19:00 on the same day.
                                </p><p>
                                    2. To maintain order on campus, visitors’ motor vehicles are not allowed to enter the campus.
                                </p><p>
                                    3. Visitors should strictly abide by the relevant campus management regulations and work together to maintain good campus order.
                                </p>
                            </div>

                        </Collapse>

                        { /*privay*/}
                        <div className="form-check privacy">
                            <input className={privacyClasses} name="privacy" type="checkbox" id="privacy" value={false} required onChange={onHandlePrivacyChange} />
                            <label className="form-check-label" htmlFor="privacy">
                                {t("ihaveread")} <NavLink to="/privacy">{t("privacy")}</NavLink>
                            </label>
                            <div className="invalid-feedback">
                                {t("You must agree before submitting")}
                            </div>
                        </div>

                        <button className="btn btn-primary" type="submit" onClick={onHandleSubmit}>{t("submit")}</button>
                    </Form>
                </div>
            </div>

            {
                isSubmitting ? (

                    resultResponse ? (

                        <div className="loading" >
                            <Container>
                            <div>
                                {t("Verify Your Email")}
                            </div>
                            <div className="emailsend">
                                {
                                    i18n.language.startsWith('zh') ?
                                        `邮箱报名确认链接已发送至${emailaddress}，请前往邮箱确认参会报名！` : `An email has been sent to ${emailaddress}, please click the link in the email to continue.`
                                }
                            </div>
                            </Container>
                        </div>) : (<div className="loading">
                        <Container>
                            <div className="loader"></div>
                                <div>{t("Submiting")}</div>
                        </Container>
                        </div >)
                ) : false

            }

        </>
    );
}