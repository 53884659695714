import React, { Suspense } from 'react';
import NavMenu from './NavMenu';
import Footer from './Footer';
import Start from './Start';
import { Outlet, useLoaderData, Await } from "react-router-dom";
import './Layout.css';



export default function Layout() {

    const data = useLoaderData();

    return (

        <div className="layout">
            <Suspense fallback={<Start />} >
                <Await resolve={data.Result}>
                    {
                        (result) => {
                            if (!result) {
                                return false;
                            }

                            const { yearPlayBacks, ypmap } = result;
                            if (yearPlayBacks && ypmap) {
                                return (
                                    <NavMenu useSuspense={false} reviews={yearPlayBacks} speakers={ypmap}>
                                    </NavMenu>
                                );
                            }
                            else {
                                return false;
                            }
                            
                        }
                    }
                    
                </Await>
            </Suspense>
            <Outlet />

            <Footer />
        </div>


    );
}

