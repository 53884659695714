import React, { createRef, Component } from 'react';
import {NavItem} from 'reactstrap';
import { NavLink } from "react-router-dom";
import "./Exciting.css"
import classNames from 'classnames';
import { Helmet } from "react-helmet";
import { withTranslation } from 'react-i18next';
class Exciting extends Component {
    static displayName = Exciting.name;
    constructor(props) {
        super(props);
        this.onHandlePreClick = this.onHandlePreClick.bind(this);
        this.onHandleNextClick = this.onHandleNextClick.bind(this);
        this.containerRef = React.createRef();
    }


    onHandlePreClick(e) {

        let scrollWidth = this.containerRef.current.offsetWidth;
        //   this.containerRef.current.scrollTo()
        this.containerRef.current.scrollBy(-scrollWidth, 0);
    }

    onHandleNextClick(e) {


        let scrollWidth = this.containerRef.current.offsetWidth;
        // console.log(scrollWidth);
        this.containerRef.current.scrollBy(scrollWidth, 0);
    }

    render() {
        const { playbacks } = this.props;
        return (

            <>
                <ul className="exciting_container" ref={this.containerRef}>
                    {
                        playbacks&&playbacks.map(p => (
                            <NavItem key={p.playbackId }>
                                <NavLink className='li_item' to={`/review/${p.year}/${p.playbackId}` }>
                                    <div className="poster_container">
                                        <img alt={p.title} src={ p.posterUrl} />
                                    
                                            <div className="play_video">
                                                <i className="bi bi-play-circle"></i>
                                            </div>
                                    </div>
                                    <article className="speaker_intro">
                                        <section>
                                                <div className="play_title clamp_ecllipse">{ p.title}</div>
                                            <div className="play_speaker clamp_ecllipse">
                                                <span>{ p.speaker.speakerName}</span>
                                            </div>
                                            <div className="speaker_insti clamp_ecllipse">
                                                {p.speakerTitle} {p.speaker.speakerInstitution }
                                            </div>
                                        </section>
                                    </article>
                                </NavLink>
                            </NavItem>
                        )


                        )

                    }

                    {/*
                    <li className="li_item" data-index="1">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111309595390.jpg" />
                            <div className="play_icon_container">
                                <div className="play_icon"></div>
                            </div>
                        </div>
                        <article className="speaker_intro">
                            <section>
                                <div className="play_title clamp_ecllipse">Understanding Brain Cell Type Diversity</div>
                                <div className="play_speaker clamp_ecllipse">
                                    <span>Hongkui Zeng</span>&nbsp;<span>Ph.D.</span>
                                </div>
                                <div className="speaker_insti clamp_ecllipse">
                                    Executive Vice President and Director, Allen Institute for Brain Science
                                </div>
                            </section>
                        </article>
                    </li>
                    <li className="li_item" data-index="2">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111322450439.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="3">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111335927569.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="4">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111353560648.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="5">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111459625022.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="6">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111512258147.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="7">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111524636216.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="8">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111538575346.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="9">
                        <div className="poster_container">
                            <img alt="abc" src="/images/poster/20221019111552773488.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111604918501.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111658462908.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111725824162.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111741649257.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111753970306.jpg" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111807782444.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111820742578.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019142334015741.png" />
                        </div>
                    </li>
                    <li className="li_item" data-index="10">
                        <div className="poster_container" >
                            <img alt="abc" src="/images/poster/20221019111406847739.png" />
                        </div>
                    </li>
                    */ }
                </ul>
                <div className="arrow_container">
                    <button aria-hidden="true" className="arrow_left" onClick={this.onHandlePreClick}>
                        <i className="bi bi-chevron-left"></i>
                    </button>
                    <button className="arrow_right" onClick={this.onHandleNextClick}>
                        <i className="bi bi-chevron-right"></i>
                    </button>
                </div >
            </>
        );
    }
}


export default withTranslation()(Exciting);