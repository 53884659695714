import { Container, NavItem } from 'reactstrap';
import { useState, useEffect, Suspense } from 'react';
import { useLoaderData,Await } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { useCookies } from 'react-cookie';

import './Agenda.css';


export default function Agenda() {  

    const [cookies, setCookie] = useCookies(['language']);
    const [language, setlanguage] = useState(cookies.language);

    useEffect(() => {
        const newLanguage = cookies.language;
        if (newLanguage !== language) {
            window.location.reload();
        }

    }, [cookies.language]);


    const data = useLoaderData();
    const [t, i18n] = useTranslation();

    


    const [activeDate, setactiveDate] = useState("");

    function onHandleDateClick(e) {
        const date = e.currentTarget.getAttribute('data-date');
        setactiveDate(date);
    }
    

 
 
    
    return (
        <>
            <Helmet>
                <title>{t("agenda")}</title>
            </Helmet>
        <div className="agenda_area">
            
            <Container>
                <div className="agenda_title">
                        <p className="agenda_main">{t("Program of Single Cell Omics Beijing 2023")}</p>
                        <p className="agenda_date">{t("Oct. 14-15, 2023")}</p>
                        <p> {t("(Beijing Time, GMT+8)")}</p>
                </div>
                <div className="agenda_content">
                        <ul className="agenda_header">
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (!result) {
                                                return false;
                                            }
                                            const { agendas } = result;
                                            if (agendas) {
                                               var activeAgenda = agendas.find(a => new Date(a.agendaTime).toLocaleDateString() === activeDate)??agendas[0];
                                               return agendas.map(agenda => {
                                                    const dateOptions = { month: 'short', day: 'numeric' };
                                                    const weekOptions = { weekday: 'short' }
                                                   const date = new Date(agenda.agendaTime);
                                                   if (new Date(agenda.agendaTime).toLocaleDateString() === new Date(activeAgenda.agendaTime).toLocaleDateString() ){
                                                        return (
                                                            <li key={agenda.agendaId} className="agenda_date_active" onClick={onHandleDateClick} data-date={new Date(agenda.agendaTime).toLocaleDateString()}>
                                                                <span >
                                                                    {date.toLocaleDateString(i18n.language, dateOptions)}, {date.toLocaleDateString(i18n.language, weekOptions)}
                                                                </span>

                                                            </li>
                                                        )
                                                    }
                                                    return (
                                                        <li key={agenda.agendaId} onClick={onHandleDateClick} data-date={new Date(agenda.agendaTime).toLocaleDateString()} >

                                                            <span >
                                                                {date.toLocaleDateString(i18n.language, dateOptions)}, {date.toLocaleDateString(i18n.language, weekOptions)}
                                                            </span>

                                                        </li>
                                                    )
                                                })
                                            }
                                            
                                        }
                                    }
                                </Await>
                            </Suspense>
                    
                    </ul>
                        <div className="agenda_detail">
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (!result) {
                                                return false;
                                            }

                                            const { agendas, sessionItems } = result;
                                            
                                           
                                            const activeAgenda = agendas.find(agenda => new Date(agenda.agendaTime).toLocaleDateString() === activeDate) ?? agendas[0];
                                            
                                            const sessions = activeAgenda.sessions;
                                            let activeSessionItems = [];
                                            if (activeDate === '') {
                                                
                                                activeSessionItems = sessionItems.filter(s => new Date(s.sessionItem.start).toLocaleDateString() === new Date(activeAgenda.agendaTime).toLocaleDateString());

                                            }
                                            else {
                                                activeSessionItems = sessionItems.filter(s => new Date(s.sessionItem.start).toLocaleDateString() === activeDate);
                                            }
                                            
                                            let sessionIndex = 0;
                                            return sessions.map(session => {
                                                if (session.sessionIndex !== sessionIndex) {
                                                    sessionIndex = session.sessionIndex;
                                                    return (
                                                        <div key={session.sessionId} className="agenda_session">
                                                            <div className="agenda_session_header">{t("Session")}&nbsp;{session.sessionIndex}:&nbsp;{session.sessionName}</div>
                                                            <div className="agenda_items_container">
                                                                {
                                                                    activeSessionItems.map(sessionItem => {
                                                                        let timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h24' };
                                                                        if (sessionItem.session.sessionIndex === session.sessionIndex) {
                                                                            let starttime = new Date(sessionItem.sessionItem.start);
                                                                            let endtime = new Date(sessionItem.sessionItem.end);
                                                                            return (
                                                                                <div key={sessionItem.sessionItem.index} className="agenda_sessionitem">
                                                                                    <div className="sessionItem_time">{starttime.toLocaleTimeString(i18n.language, timeOptions)}-{endtime.toLocaleTimeString(i18n.language, timeOptions)}</div>
                                                                                    <div className="sessionItem_title_speaker">
                                                                                        <div className="sessionItem_speaker">
                                                                                            <div className="sessionItem_speaker_name">
                                                                                                {sessionItem.sessionItemSpeaker.speakerName}
                                                                                            </div>
                                                                                            <div>
                                                                                                {sessionItem.sessionItemSpeaker.speakerInstitution}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="sessionItem_title">
                                                                                            {sessionItem.sessionItem.title}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return false;
                                                                        }
                                                                        


                                                                    }
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                else {
                                                    return false
                                                }
                                            }
                                            )
                                        }
                                    }
                                </Await>
                            </Suspense>
                        {

                            
                        }


                    </div>
                </div>

            </Container>
            </div>
        </>
    );
}