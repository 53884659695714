/* eslint-disable react/no-danger-with-children */
import React, { useRef, Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import Chairman from './Chairman';
import Htitle from './Htitle';
import HCtitle from './HCtitle';
import Sponsors from './Sponsors';
import Sponsor from './Sponsor';
import Exhibition from './Exhibition';
import Exciting from './Exciting';
import { useCookies } from 'react-cookie';
import Mask from './Mask';

import HomeSpeakers from './HomeSpeakers';
import './IntroItem.css'
import { useLoaderData, Await, useFetcher, useAsyncValue } from "react-router-dom";
import StreamPlayer from './StreamPlayer';
import videojs from 'video.js';



import Carousel from 'react-bootstrap/Carousel';
import { Helmet } from "react-helmet";

import "./Home.css"
import { useEffect, useState } from 'react';
import { throttle } from "./utilis"


export default function Home() {
    
    //data is result
    const data = useLoaderData();
    
    
    

    const playerRef = useRef(null);

    const streamRef = useRef(null);

    const imgRef = useRef(null);

    const [isLive, setisLive] = useState(false);


    const [imgBannerStyle, setimgBannerStyle] = useState({});
    const [imgStyle, setimgStyle] = useState({});



    useEffect(() => {
        
        data.Result.then(result => {
            setisLive(result.live);
        })
        window.addEventListener('resize', throttle(onHandleResize, 100));
        if (window.innerWidth < 768) {
            if (isLive) {
                if (streamRef.current != null) {
                    var height = streamRef.current.offsetHeight;
                    setimgBannerStyle({ height: 2 * height });
                    setimgStyle({});
                }
            }
            else {
                setimgBannerStyle({ "aspectRatio": "16/9" });
                if (imgRef.current) {
                    setimgStyle({ width: '100%' });
                }
            }
        }
        else {
            setimgBannerStyle({});
            setimgStyle({});
        }

    }, [data,isLive]);

    function onHandleResize() {
        if (window.innerWidth < 768) {
            if (isLive) {
                if (streamRef.current != null) {
                    var height = streamRef.current.offsetHeight;
                    setimgBannerStyle({ height: 2 * height });
                    setimgStyle({});
                }
            }
            else {
                setimgBannerStyle({ "aspectRatio": "16/9" });
                if (imgRef.current) {
                    setimgStyle({ width: '100%' });
                }
            }

        }
        else {
            setimgBannerStyle({});
            setimgStyle({});
        }
    }


    const chairsContainerRef = useRef(null);

    function onHandleLoad() {
        setisLive(true);
    }


    function onHandlePlayerReady(player) {
        playerRef.current = player;
        player.on('waiting', () => {
            videojs.log("player is waiting");
        });
        player.on('dispose', () => {
            videojs.log('player will dispose');
        });
    }


    function onHandleHover() {
        const chairsContainerWidth = chairsContainerRef.current.clientWidth;
        return chairsContainerWidth;
    }




    const { t, i18n } = useTranslation();

   




    return (
        <>
            <Helmet>
                <title>{t("home")}</title>
            </Helmet>


            {/*         <iframe src="https://m.inmuu.com/v1/services/app/player/play/0JpLwIWKEaQ5xtsmyMY4KaVt" height="500px" scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" loading="lazy"></iframe>

                */}
            <Carousel indicators={false} prevIcon={""} nextIcon={""}>
                <Carousel.Item>
                    <div className="carousel-img" style={imgBannerStyle}>
                        <img ref={imgRef} alt="sco" text="First slide" src="/images/sco_kv.jpg" style={imgStyle} />
                    </div>

                    <Carousel.Caption className="carousel_caption carousel_container">

                        <div className="banner_title">
                            <h3>{t("Single Cell Omics Beijing 2023")}</h3>
                            <h5>{t("October 14-15, 2023")}</h5>
                            <h5>{t("Beijing, China")}</h5>
                            <Suspense fallback={<p>{t("Loading...")}</p>}>
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            const { playbacks } = result;
                                            const activeItem = playbacks.at(1);
                                            return (<div>
                                                <NavLink className="register_now" to={`/review/${activeItem.year}/${activeItem.playbackId}`} ><span>{t("PlayBack")}</span></NavLink>
                                            </div>
                                            );
                                            
                                        }
                                        
                                    }
                                </Await>
                            </Suspense>
                            {/*
                            <Suspense >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            const { live } = result;
                                            return live ? false : (<div>
                                                <NavLink className="register_now" to={"/authentication/register"} ><span>{t("register")}</span></NavLink>
                                            </div>);
                                        }
                                    }
                                </Await>
                            </Suspense>
                            */}
                        </div>
                        <Suspense fallback={<p>{t("Loading...")}</p>} >
                            <Await resolve={data.Result}>
                                {
                                    (result) => {
                                        const { live, liveSrc } = result;
                                       
                                        if (live) {
                                            const videoJsOptions = {
                                                
                                                controls: true,
                                                responsive: true,
                                                fluid: true,
                                                enableDocumentPictureInPicture: true,
                                                sources: [{
                                                    src: "https://liveplay.xiaoeknow.com/live/5060_799ade14c40084epu.m3u8",
                                                    type: 'application/x-mpegURL'
                                                }]
                                            }
                                            
                                            return (<div className="broad_live" ref={streamRef} >
                                                {/* <StreamPlayer options={videoJsOptions} onReady={onHandlePlayerReady} />*/}

                                                <iframe title="livestream" src={liveSrc} scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true" loading="lazy">
                                                    
                                                </iframe>
                                                <div className="stream_live">{t("LIVE")}</div>
                                            </div>)
                                        }
                                        return false;
                                    }
                                }
                            </Await>
                        </Suspense>



                    </Carousel.Caption>
                    <Suspense fallback={<p>{t("Loading...")}</p>}>
                        <Await resolve={data.Result}>
                            {
                                (result) => {
                                    const { live,
                                        isVerified } = result;
                                    return live ? (isVerified ? false : <Mask />) : false
                                }
                            }
                        </Await>
                    </Suspense>


                </Carousel.Item>
                {/*
                    <Carousel.Item>
                        <div className="carousel-img">
                            <img alt="sco" text="First slide" src="/images/sco_kv.jpg" />
                        </div>
                        <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="carousel-img">
                            <img alt="sco" text="First slide" src="/images/sco_kv.jpg" />
                        </div>
                        <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>
                                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
                            </p>
                        </Carousel.Caption>

                    </Carousel.Item>
                     */ }

            </Carousel>

            <div className="topic_area">
                <Container>
                    <div className="chair_area">
                        <div className="chair_title">
                            {t("chairmen")}
                        </div>
                        <div className="chair_container" ref={chairsContainerRef}>
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (result.chairs) {
                                                return result.chairs.map((c, index) =>
                                                    <Chairman index={index + 1} key={c.index} name={c.name} src={c.imageUrl} onHandleHover={onHandleHover} html={{ __html: c.description }} >

                                                    </Chairman>
                                                )
                                            }
                                            return false


                                        }
                                    }
                                </Await>
                            </Suspense>
                            {



                            }
                            {/*
                            <Chairman index="1" name="Yanyi Huang" src="/images/yanyihuang.jpg" onHandleHover={onHandleHover}>
                                <p>PI, Beijing Advanced Innovation Center for Genomics</p>
                                <p>PI, Biomedical Pioneering Innovation Center at Peking University</p>
                                <p>Recipient of National Science Fund for Distinguished Young Scholars</p>
                            </Chairman>
                            <Chairman index="2" name="Xiaoliang Sunney Xie" src="/images/sunneyxie.jpg" onHandleHover={onHandleHover}>
                                <p>Director, Beijing Changping Laboratory</p>
                                <p>Lee Shau-kee Professor of Peking University</p>
                                <p>PI, Beijing Advanced Innovation Center for Genomics</p>
                                <p>PI, Biomedical Pioneering Innovation Center</p>
                            </Chairman>
                            <Chairman index="3" name="Fuchou Tang" src="/images/fuchoutang.jpg" onHandleHover={onHandleHover}>
                                <p>PI, Beijing Advanced Innovation Center for Genomics</p>
                                <p>PI, Biomedical Pioneering Innovation Center at Peking University</p>
                                <p>Recipient of National Science Fund for Distinguished Young Scholars</p>
                            </Chairman>
                            */ }
                        </div>
                    </div>
                </Container>
            </div>
            <div className="o_area">
                <Container>
                    <div className="overview_area">
                        <div className="overview_title">
                            <p>
                                {t("sco_overview")}
                            </p>
                        </div>
                        <div className="overview_content">
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>{

                                    (result) => (<div dangerouslySetInnerHTML={{ __html: result.aboutus }}></div>)
                                }

                                </Await>
                            </Suspense>
                        </div>
                        <div className="learn_more">
                            <Link to="/aboutus">{t("Learn More")}</Link>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="exciting">
                <Container>
                    <div className="exciting_area">
                        <Htitle title={t("exciting_playback")} to={"/review/2022"} />
                        <div className="exciting_content">
                            <div>
                                <Suspense fallback={<p>{t("Loading...")}</p>} >
                                    <Await resolve={data.Result}>{
                                        (result) => <Exciting playbacks={result.playbacks} />
                                    }
                                    </Await>
                                </Suspense>
                            </div>
                            {/*
                                <MediaPlayer
                                    title="Sprite Fight"
                                    src="https://stream.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/low.mp4"
                                    poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
                                    thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
                                    aspectRatio={16 / 9}
                                    crossorigin=""
                                >

                                    <MediaOutlet>
                                        <MediaPoster
                                            alt="Girl walks into sprite gnomes around her friend on a campfire in danger!"
                                        />

                                    </MediaOutlet>
                                    <MediaCommunitySkin />
                                </MediaPlayer>
                            */}


                        </div>
                    </div>
                </Container>
            </div>
            <div className="h_speakers">
                <Container>
                    <div className="h_speaker_area">
                        <Htitle title={t("sco_2023_speakers")} to={"/speakers/2023/3"} />
                        <Suspense fallback={<p>{t("Loading...")}</p>} >
                            <Await resolve={data.Result}>{
                                (result) => {
                                    if (!result) {
                                        return false;
                                    }
                                    return <HomeSpeakers speakers={result.speakers} />
                                }
                            }

                            </Await>
                        </Suspense>
                    </div>
                </Container>
            </div>
            <div className="h_exhibition">
                <Container>
                    <div className="h_exhibition_container">
                        <HCtitle />
                        <Exhibition />
                    </div>
                </Container>
            </div>
            <div className="h_sponsors">
                <Container>
                    <div className="h_sponsors_area">
                        <Htitle title={t("cooperativepartner")} />
                        <Sponsors >
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (result.sponsors) {
                                                return result.sponsors.map(
                                                    sponsor => (
                                                        <Sponsor key={sponsor.sponsorName} title={sponsor.sponsorName} src={sponsor.sponsorImageUrl} website={sponsor.sponsorWebsite} />
                                                    )
                                                )
                                            }
                                            return false;

                                        }
                                    }
                                </Await>
                            </Suspense>
                            {/*
                            <Sponsor title="Abosciences" src="/images/sponsors/abiosciences.svg" />
                            <Sponsor title="10X Genomics" src="/images/sponsors/10X.svg" />
                            <Sponsor title="Nanopore Technologics" src="/images/sponsors/nanopore.svg" />
                            <Sponsor title="Illumina" src="/images/sponsors/illumina.svg" />
                            <Sponsor title="Novogene" src="/images/sponsors/novogene.svg" />
                            */ }
                        </Sponsors>
                    </div>
                </Container>
            </div>

        </>
    );

}
