import React, { createRef, Children, Component } from 'react';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import './HomeSpeakers.css';
import HomeSpeaker from './HomeSpeaker';
class HomeSpeakers extends Component {
    static displayName = HomeSpeakers.name;
    constructor(props) {
        super(props);
        this.containerRef = createRef();
    }

    onHandleHover = (e) => {
        const style=getComputedStyle(this.containerRef.current)
        return (
        {
            width: this.containerRef.current.offsetWidth,
                height: this.containerRef.current.offsetHeight,
                count: this.containerRef.current.childElementCount,
                rowGap: parseInt(style.getPropertyValue('row-gap'))
        });
    }

    render() {
        const { speakers } = this.props;
        return (
            <ul className="h_speaker_container a_row" ref={this.containerRef}>
                {
                    speakers && speakers.map((speaker, index) => (
                        <HomeSpeaker key={speaker.speakerId} to={`/speakers/${speaker.year}/${speaker.speakerId}`} index={index + 1} alt={speaker.speakerName} src={speaker.speakerUrl} name={speaker.speakerName} institution={speaker.speakerInstitution} onHandleHover={this.onHandleHover}>
                             <div dangerouslySetInnerHTML={{ __html: speaker.speakerDescription }}></div>
                        </HomeSpeaker>
                    ))
                    
                }

                {/*

                <HomeSpeaker index='1' alt='aibinhe' src="/images/speakers/2023/aibinhe.png" name="Aibin He" title="PI" institution="Peking University" onHandleHover={this.onHandleHover}>
                    Dr. Aibin He received his Ph.D. degree at Peking Union Medical College, and did his postdoc training with Dr. William T. Pu at Harvard Medical School and Boston Children�s Hospital. Dr. He started his own independent research at Institute of Molecular Medicine and Center for Life Sciences, Peking University in 2014. His lab is enthusiastic in developing novel technologies in single-cell omics and live imaging at large scales to understanding of genome regulation and cell lineage decisions in development, organ regeneration and disease. Fascinated by precisely controlled cardiac progenitor cells (CPCs) differentiation and allocation during heart development, we mainly focus on understanding of cellular origins and fates, and epigenetic mechanisms of cardiogenesis and heart regeneration. Particularly, our research directions include but are not limited to the following two aims: Aim 1 is to probe epigenetic underpinnings of CPCs specification and fate choice determination at single-cell level. To this end, we will combine single-cell transcriptomic profiling with in-house developed new single-cell epigenomic techniques to define the key intermediate lineages specific enhancers and master transcription factors centered regulatory network. Aim 2 is to reconstruct digital cell lineage roadmap of early heart development and regeneration through custom digital scanned light-sheet microscopy. The goal of this aim is to map holistic heart cells� behaviors, including proliferation, specification, and allocation during differentiation to form distinct parts of the heart. The multi-disciplinary knowledge from developmental biology, epigenetics, bioinformatics, optical microscopy and mathematical modeling will be harnessed to tackle these above questions.
                </HomeSpeaker>
                <HomeSpeaker index='2' onHandleHover={this.onHandleHover} alt='angelaruohaowu' src="/images/speakers/2023/angelaruohaowu.jpg" name="Angela Ruohao Wu" title="Associate Professor" institution="Hong Kong University of Science" >
                    Angela Ruohao Wu is an Associate Professor jointly appointed in the Division of Life Science and the Department of Chemical and Biological Engineering at The Hong Kong University of Science and Technology. She completed her Ph.D., and post-doctoral training in Bioengineering at Stanford University. Her current research interests are developing genomics and microfluidics-based tools for precise cellular analysis and manipulation, and to address biological questions in cancer, development, and marine biology. Angela was named one of MIT Technology Review Innovators under 35 Asia in 2016; a World Economic Forum Young Scientist in 2018, and an Outstanding Young Faculty by IEEE EMBS (Micro and Nanotechnology in Medicine) in 2018. Angela also co-founded Agenovir Corporation, a CRISPR-based therapeutics company targeting infectious diseases for a complete cure.
                </HomeSpeaker>
                <HomeSpeaker index='3' onHandleHover={this.onHandleHover} alt='catherwong' src="/images/speakers/2023/catherineclwong.jpg" name="Catherine C.L. Wong" title="Associate Professor" institution="Peking University Health Science Center" >
                    Dr. Catherine C.L. Wong is Director of Center for Precision Medicine Multi-omics Research (CPMMR), and Principal Investigator of Peking University-Tsinghua University Center for Life Sciences (CLS). She has been dedicated to the development of mass spectrometry technology and the methodological application of proteomics in basic and clinical research. Currently, the single-cell proteomics technology developed by her team maintains a leading position in the aspect of the identified protein number. Besides, Professor Wong has developed many innovative methods for identification of post-translational modification of various difficult proteins. Among them, the first direct identification of arginylation substrates and the accurate specific modified sites, has successfully filled up the blank and opened up the whole research field of arginylation. Up to present, she has published 73 SCI papers, including Science, Nature Communications, PNAS and so on.
                </HomeSpeaker>

                <HomeSpeaker index='4' onHandleHover={this.onHandleHover} alt='chenghangzong' src="/images/speakers/2023/chenghangzong.jpg" name="Chenghang Zong" title="Assistant Professor" institution="Baylor College of Medicine" >
                    The research of our laboratory focuses on the development of novel single-cell technologies and their applications in biology studies. With the rapid development of next-generation sequencing technology, high-throughput sequencing has become a powerful tool for biological research. In our lab, we are interested in examining the genome at single-cell resolution, in contrast to the genome averaged from an ensemble of cells. We are particularly interested in detecting early mutations that drive tumorigenesis and the early stage of tumor heterogeneity that will influence later tumor development. The lab specifically focuses on pancreatic cancer. Supported by NIH�s New Innovator�s award, we have successfully developed a linear amplification-based whole-genome amplification method (LCS-WGA), which allows us to profile not only the somatic mutations existing in single tumor cells but also the spontaneous DNA damage in single cells, which we refer to as damagenome. The successful profiling of DNA damagenome promoted us to identify the high-damage genes existing in the human genome and unveil their association with complex human diseases.
                </HomeSpeaker>
                <HomeSpeaker index='5' onHandleHover={this.onHandleHover}  alt='dongxing' src="/images/speakers/2023/dongxing.jpg" name="Dong Xing" title="PI" institution="Peking University" >
                    Dr. Dong Xing received his Ph.D. at Peking University in 2012 and completed his postdoc training with Prof. Xiaoliang Sunney Xie at Harvard University. Dr. Xing�s research is in the field of single-cell genomics. Together with colleagues, he developed single-cell genomic analysis methods including LIANTI, META-CS and Dip-C. Dr. Xing joined Peking University as an assistant professor in 2019.
                </HomeSpeaker>
                <HomeSpeaker index='6' onHandleHover={this.onHandleHover} alt='fanbai' src="/images/speakers/2023/fanbai.png" name="Fan Bai" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='7' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/fuchoutang.jpg" name="Fuchou Tang" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='8' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/gegao.jpg" name="Ge Gao" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='9' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/guojiguo.jpg" name="Guoji Guo" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='10' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/jianbinwang.jpg" name="Jianbin Wang" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='11' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/jianma.jpg" name="Jian Ma" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='12' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/sunneyxie.jpg" name="Xiaoliang Sunney Xie" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='13' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/xianwenren.jpg" name="Xianwen Ren" title="Professor" institution="BIOPIC & ICG, Peking University" />
                <HomeSpeaker index='14' onHandleHover={this.onHandleHover} alt='fuchoutang' src="/images/speakers/2023/yanyihuang.jpg" name="Yanyihuang" title="Professor" institution="BIOPIC & ICG, Peking University" />
*/ }
            </ul>
        );

    }
}
export default withTranslation()(HomeSpeakers);