import Sidebar from './Sidebar.js';
import { Suspense, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { Container, NavItem, Collapse } from 'reactstrap';
import { useLocation, NavLink, Await, useLoaderData, Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './PlayBack.css';
import './Sidebar.css';
import PlayVideo from './PlayVideo';
import { useCookies } from 'react-cookie';

export default function Review() {

    const [activeYear, setactiveYear] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    function throttle(callback, delay = 200) {
        let timer;
        return (...args) => {
            if (timer) return
            timer = setTimeout(() => {
                callback(...args);
                timer = null;
            }, delay)
        }
    }

    function onHandleResize() {
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }

    }

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }



        window.addEventListener('resize', throttle(onHandleResize));


    }, [window])

    const toggle = () => setIsOpen(!isOpen);

    function onHandleYearClick(e) {
        const year = e.currentTarget.getAttribute("data-year");
        setactiveYear(year);
        setIsOpen(false);
    }


    const { t } = useTranslation();
    const location = useLocation();
    const data = useLoaderData();
    return (
        <>
            <Helmet>
                <title>{t("review")}</title>
            </Helmet>

            <div className="playback">
                <Container>
                    <div className="review_container">
                        <div color="primary" className="review_toggle" onClick={toggle} style={{ marginBottom: '1rem' }}>
                            <div>
                                <Suspense
                                    fallback={<p>{t("Loading...")}</p>}
                                >
                                    <Await
                                        resolve={data.Result}
                                    >

                                        {
                                            (result) => {
                                                if (!result) {
                                                    return false;
                                                }

                                                const { yearPlayBacks } = result;
                                                
                                                //const activeyearPlayback=yearPlayBacks.filter(y => location.pathname.includes(y.year));
                                                
                                               return  (
                                                   <>
                                                       <span>{activeYear ? activeYear: yearPlayBacks[0].year}</span>&nbsp;
                                                        {
                                                            isOpen ? (<i className="bi bi-chevron-down"></i>) : (<i className="bi bi-chevron-right"></i>)
                                                        }
                                                    </>)
                                            }
                                        }


                                    </Await>

                                </Suspense>
                            </div>
                        </div>
                        <Collapse isOpen={isOpen}>
                            <Sidebar>
                                <Suspense
                                    fallback={<p>{t("Loading...")}</p>}
                                >
                                    <Await
                                        resolve={data.Result}
                                    >
                                        {
                                            (result) => {
                                                if (!result) {
                                                    return false;
                                                }
                                                const { yearPlayBacks } = result;
                                                if (yearPlayBacks) {
                                                    return yearPlayBacks.map((yp) => (
                                                        <li onClick={onHandleYearClick} key={yp.year} data-year={yp.year} className={location.pathname.toLowerCase().startsWith(`/review/${yp.year}`) ? "navitemactive nav-item" : 'nav-item'}>
                                                            <NavLink to={`/review/${yp.year}`} className={({ isActive, isPending }) =>
                                                                isPending ? "pending" : isActive ? "sideactive" : ""
                                                            }>{yp.year}</NavLink>
                                                        </li>
                                                    ))
                                                }
                                                return false

                                            }
                                        }
                                        
                            
                                    </Await>

                                </Suspense>
                            </Sidebar>
                        </Collapse>
                        <Outlet />
                    </div>
                </Container>
            </div>
        </>
    );
}
export function PlayBack() {
    const data = useLoaderData();
    const { t } = useTranslation()
    return (
        <div className="review_list">
            <Suspense
                fallback={<p>{t("Loading...")}</p>}
            >
                <Await
                    resolve={data.Result}
                >
                    {
                        (result) => {
                            if (!result) {
                                return false;
                            }
                            const { videos } = result;

                            return videos.map(video => (
                                <PlayVideo alt={video.videoTitle} key={video.videoId} src={video.videoPosterUrl} title={video.videoTitle} name={video.videoSpeaker.speakerName} occupation={video.videoSpeaker.speakerInstitution} to={`/review/${video.videoYear}/${video.videoId}`}>
                                </PlayVideo>
                            ))
                        }
                    }


                </Await>


            </Suspense>
            
        </div>
    )

}