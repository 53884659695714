import { useTranslation } from 'react-i18next';
import { Outlet } from "react-router-dom";
import { Container } from 'reactstrap';
import './Authentication.css';
import Footer  from '../Footer';

export default function Authentication() {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <div className="h_banner">

            
                <Container>
                    
                    <div>
                        <div className="auth_logo">
                            <img alt="logo" src="/logo.svg" />
                        </div>
                        <div className="authenticate_container">
                            <Outlet />
                        </div>
                        
                        <div>
                            
                        </div>
                    </div>
                        


                </Container>
            
        </div >
            <Footer />
        </div>
    )
}