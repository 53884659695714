import React, { Component } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ChevronRight } from 'react-bootstrap-icons';
import './Htitle.css';
class Htitle extends Component{



    render() {
        const { t, title ,to} = this.props;
        return (
            <Link className="htitle_nav" to={to }>
                <div className="htitle">
                <span>{ t(title)}</span>
                    <ChevronRight />
                </div>
            </Link>
        );
    }
}

export default withTranslation()(Htitle)