import "./Mask.css";
import { useState } from 'react';
import { Container } from 'reactstrap';
import { Link, useFetcher } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { useTranslation } from 'react-i18next';

export default function Mask() {
    const fetcher = useFetcher();
    const { t ,i18n} = useTranslation();
    const [emailaddress, setemailAddress] = useState(null);
   
   
    
    function onHandleBlur(e) {
        let email = e.target.value;
        setemailAddress(email);
    }


    return (
        <>
            {
                fetcher.data && fetcher.data.isConfirmed===true ? false : (
                    <div className="home_login" >
                        <Container>
                            <div className="login_container">
                                <div className="mask_title">
                                    <h3> {t("Watch Live Online!")} </h3>
                                    <h5>{t("Please Verify Your Email Address")}</h5>
                                </div>
                                <div className="mask_form">
                                    <fetcher.Form method="post" className="needs-validation" action="/home" >
                                        <div className="form-group row">
                                            <div >
                                                <input name="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder={t("Enter Your Registered Email")} onBlur={onHandleBlur} />
                                                {fetcher.data ? (fetcher.data.isRedirect ? (
                                                    <div id="email_feedback" className="email_feedback">{t("This email address has not been registered")}</div>
                                                ) : false)
                                                    : false
                                                }
                                            </div>

                                        </div>

                                        <button type="submit" className="btn btn-primary">{t("Submit")}</button>

                                    </fetcher.Form>
                                    <div className="mask_register">
                                        <div>{t("Don't have registered for this meeting?")} <Link to="/authentication/register">{t("Register Now")}</Link></div>
                                    </div>
                                </div>


                            </div>
                        </Container>
                        {
                            fetcher.state === "submitting" ? (
                                <div className="loading">
                                <Container>
                                    <div className="loader"></div>
                                    <div>{t("Submiting")}</div>
                                    </Container>
                                </div >) : (fetcher.data && fetcher.data.isConfirmed === false && fetcher.data.isRedirect===false ? (

                                <div className="loading" >
                                    <Container>
                                        <div>
                                            {t("Verify Your Email")}
                                        </div>
                                        <div className="emailsend">
                                            {
                                                i18n.language.startsWith('zh') ?
                                                    `邮箱报名确认链接已发送至${emailaddress}，请前往邮箱确认参会报名！` : `An email has been sent to ${emailaddress}, please click the link in the email to continue.`
                                            }
                                        </div>
                                    </Container>
                                </div>):false)
                        }
                    </div>
                )    
            }
            
        </>


    )
}