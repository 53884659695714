import './Exhibition.css';
import './Layout.css';
import { Link} from "react-router-dom";
export default function Exhibition() {
    return (
        <div className="exhibition_center">
            <img alt="exhibition center" src="/images/exhibition.png" />
            <div className="play_icon">
                <Link to="exhibition/"  reloadDocument target="_blank">
                <i className="bi bi-play-circle"></i>
                </Link>
            </div>
        </div>
    )
}