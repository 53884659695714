import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const language = navigator.language;

i18n.use(i18nBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        lng: getCookie("language"),
        debug:true,
        interpolation: {
            escapeValue:false
        }
    })

export default i18n;

function getCookie(name) {
    let lng = navigator.language;
    if (!document.cookie) {
        return lng;
    }
    
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
        
    }
    return lng;

    
}