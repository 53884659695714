import React, { Suspense, useState, useEffect } from 'react';
import './About.css';
import { Container } from 'reactstrap';
import { useLoaderData,Await } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Chairman,{ChairItem} from './Chairman';
import { Helmet } from "react-helmet";
import { useCookies } from 'react-cookie';
export default function About() {

    const [cookies, setCookie] = useCookies(['language']);
    const [language, setlanguage] = useState(cookies.language);

    useEffect(() => {
        const newLanguage = cookies.language;
        if (newLanguage !== language) {
            window.location.reload();
        }

    }, [cookies.language]);

    const { t, i18n } = useTranslation();
    const data = useLoaderData();
    return (
        <>
            <Helmet>
                <title>{t("aboutus")}</title>
            </Helmet>
        <div className="about_area">
            <Container>
                <div className="about_us">
                    <div className="about_banner">
                        <img alt="sco_kv" src="/images/sco.jpg" />
                    </div>
                    <div className="about_desc">
                            <div className="whoweare"><span>{t("Who We Are")}</span></div>


                            <div className="about_content">
                                
                                    <Suspense fallback={<p>{t("Loading...")}</p>}>
                                        <Await resolve={data.Result}>
                                            {
                                                (result) => {
                                                    if (!result) {
                                                        return false;
                                                    }

                                                    return <div dangerouslySetInnerHTML={{ __html: result.aboutus }}></div>
                                                }
                                                
                                                    
                                            }
                                        </Await>
                                    </Suspense>
                                </div>
                            <div className="about_chairmen"><span>{t("Chairmen")}</span></div>
                            <div className="chair_container" >
                                <Suspense fallback={<p>{ t("Loading...")}</p> }>
                                    <Await resolve={data.Result}>
                                        {
                                            (result) => {
                                                if (!result) {
                                                    return false;
                                                }

                                                const { chairs } = result;

                                                return chairs.map(chair => {
                                                    console.log(chair.index);
                                                    return (<Chairman index={chair.index} key={chair.index} name={chair.name} src={chair.imageUrl} html={{ __html: chair.description }}>
                                                        
                                                    </Chairman>);
                                            })
                                                
                                                
                                            }
                                        }

                                    </Await>
                                </Suspense>
                            
                        </div>
                    </div>
                </div>
                

            </Container>
            </div>
        </>
    )


}

