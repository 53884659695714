import { Container, NavItem } from 'reactstrap';
import { NavLink, useLocation } from "react-router-dom";
import './VideoItem.css';
export default function VideoItem({title, alt,src,year, to, onHandleVideoClick }) {

   
    return (
        <NavItem >
            <NavLink to={to} className={({ isActive, isPending }) =>
                isPending ? "" : isActive ? "video_slistactive" : ""
            }>
                <div className="v_crop" >
                    <img src={src} alt={alt} />
                    <div className="play_video">
                        <i className="bi bi-play-circle"></i>
                    </div>
                </div>
                <div className="v_title">
                   <span>{title}</span>
                </div>
            </NavLink>
        </NavItem>
    );
}