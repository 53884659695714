import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import './Footer.css';
import { Container } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export default function Footer() {

    const { t, i18n } = useTranslation();

    const [displayLanguage, setdisplayLanguage] = useState(i18n.language.startsWith('en')?"English":"中文");
    
    const [cookies, setCookie] = useCookies(['language']);

    const location = useLocation();
    function onHandleLinkChange(e){
        e.preventDefault();
        if (i18n.language.startsWith('en')) {
 //           setCookie('language', 'zh-CN', { path: '/' });
            fetch("/api/Cookie", {
                method: "GET",
                headers: {
                    "Accept-Language": "zh"
                }
            }).then(response => {
                if (response.ok) {
                    i18n.changeLanguage("zh");
                    setdisplayLanguage('中文');
                }
            }).then((result) => {
        //        window.location.reload();
                return true;
            });
        }
        else { 

            fetch("/api/Cookie", {
                method: "GET",
                headers: {
                    "Accept-Language": "en"
                }
            }).then(response => {
                if (response.ok) {
                    setdisplayLanguage('English');
                    i18n.changeLanguage("en");
                }
            }).then((result) => {
              //  window.location.reload();
                return true;
            });
            
        }
      //  window.location.reload();
    }

        return (
            <footer>
                <Container>
                    <div className="footer_container">
                        <div>
                            <div className="footer-logo">
                                <img src="/logo.svg" alt="logo" />
                            </div>
                        </div>
                        <div>
                            <div className="address-header">
                                <p><strong>{t("Address")}</strong></p>
                            </div>
                            <div className="address-detail">
                                <p>
                                    {t("Peking University, No.2 Integrated Science Research Building, Yiheyuan Road, No. 5, Haidian District, Beijing, P. R. China, 100871")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="wechat_logo">

                        <div>
                            <img alt="biopic" src="/images/biopic.png" />
                        </div>
                        <div>
                            <img alt="icg" src="/images/icg.png" />
                        </div>
                    </div>
                    <div className="copyright">
                        <p>{t("Copyright")}<span>&nbsp;&#169;&nbsp;</span>{t("Single Cell Omics (SCO) Beijing.")}&nbsp;{t("All Rights Reserved.")}
                        </p>
                    </div>
                    <div className="change_lng">
                        <Link to="#" onClick={onHandleLinkChange}>
                            <i className="bi bi-globe-asia-australia">&nbsp;</i>{displayLanguage}
                        </Link>
                    </div>
                </Container>
            </footer>
        );
    
}

