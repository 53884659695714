import './Authentication.css';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';
import { Container } from 'reactstrap';
import { Helmet } from "react-helmet";
import { useLoaderData, Await } from "react-router-dom";
import './Privacy.css';
export default function Privacy() {
    const { t, i18n } = useTranslation();

    const data=useLoaderData()
    return (
        <>
            <Helmet>
                <title>{t("Privacy Policy")}</title>
            </Helmet>
            <Container>
              <div className="privacy_area">
                <h1>{t("SCO Beijing")}&nbsp;{t("Privacy Policy")}</h1>
                    
                    <Suspense fallback={(<p>{t("Loading...")}</p>)}>
                        <Await resolve={data.Result}>
                            {
                            (result) => {
                                if (!result) {
                                    return false;
                                }
                                return <div dangerouslySetInnerHTML={{__html:result.privacy} }></div>
                                }
                            }

                        </Await>

                    </Suspense>
                </div>
            </Container>
        </>
    )
}