import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import './PlayVideo.css';

export default function PlayVideo({ alt, src, title, name, occupation, to }) {
    return (
        <NavLink className="playvideo" to={ to }>
            <div className="video_poster">
                <img alt={alt} src={src} />
                <div className="play_video">
                    <i className="bi bi-play-circle"></i>
                </div>
            </div>
            <div className="video_desc">
                <div className="video_title">{title}</div>
                <div className="video_author">{name}</div>
                <div className="video_occupation">{occupation}</div>
            </div>
        </NavLink>
    );
}