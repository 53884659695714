import { useTranslation } from 'react-i18next';
import { Collapse, NavItem } from 'reactstrap';
import classNames from 'classnames';
import { NavLink, useLocation,useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import "./Sidebar.css";

export default function Sidebar({ className,children }) {

    const sidebarClasses = classNames(
        className,
        'sidebar'
    );

    

    return (
        <div className={sidebarClasses}>
            <ul className="navbar-nav flex-grow" >
                {children}
            </ul>
        </div>
    )
}