import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { Collapse, NavItem } from 'reactstrap';
import { NavLink,useLocation,useMatch} from "react-router-dom";
import './Speaker.css'

export default function Speaker({ alt, src,name,institution,year,id,onHandleClick,onHandleSpeakerCheck }) {

    const speakerRef = useRef();
    let location = useLocation();
    function onHandleSpeakerClick(e) {
        
        onHandleClick({name:name});
    }

    useEffect(() => {
       
        let isChecked = location.pathname.toLowerCase().startsWith(`/speakers/${year}/${id}`);
        if (isChecked) {
            onHandleSpeakerCheck(name);
           
            
        }
        

    },[location]);

    

    return (
        <li onClick={onHandleSpeakerClick} ref={ speakerRef }>
            <NavLink to={`/speakers/${year}/${id}`} className={({ isActive, isPending }) =>
                isPending ? "pending" : isActive ? "speakeractive" : ""
            } >
                <div className="s_crop" >
                <img src={src} alt={alt} />
            </div>
            <div className="s_title">
                <p>{name}</p>
                <p>{institution}</p>
            </div>
            </NavLink>
        </li>
    )
}