import PropTypes from 'prop-types';
export function calculateToTop(element) {
    
    let next = element;
    let toTop = 0;
    do {
        toTop += next.offsetTop;
        next = next.offsetParent;
    } while (next);

    return toTop;
}


export function throttle(callback, delay = 200) {
    let timer;
    return (...args) => {
        if (timer) return
        timer = setTimeout(() => {
            callback(...args);
            timer = null;
        }, delay)
    }
}

export function debounce(func, delay,isFromClicked) {
    var timer;
    return function () {
        let self = this;
        let args = arguments;
        
        clearTimeout(timer);

        timer = setTimeout(function () {
            func.apply(self, args);
        }, delay);

        /*(isFromClicked) {
            
        }
        else {
            
            if (startTime === undefined) {
                startTime = currentTime;
            }
            if (currentTime - startTime >= 200) {
                
                func.apply(self, args);
            
                startTime = currentTime;
            }
        } */
    }
}

let globalCssModule;

export function setGlobalCssModule(cssModule) {
    globalCssModule = cssModule;
}

export function mapToCssModules(className = '', cssModule = globalCssModule) {
    if (!cssModule) return className;
    return className
        .split(' ')
        .map(c => cssModule[c] || c)
        .join(' ');
}

export const tagPropType = PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
    ]))
]);

export function omit(obj, omitKeys) {
    const result = {};
    Object.keys(obj).forEach(key => {
        if (omitKeys.indexOf(key) === -1) {
            result[key] = obj[key];
        }
    });
    return result;
}

export const keyCodes = {
    esc: 27,
    space: 32,
    enter: 13,
    tab: 9,
    up: 38,
    down: 40,
    home: 36,
    end: 35,
    n: 78,
    p: 80,
};

export const canUseDOM = !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
);

export function isReactRefObj(target) {
    if (target && typeof target === 'object') {
        return 'current' in target;
    }
    return false;
}

function getTag(value) {
    if (value == null) {
        return value === undefined ? '[object Undefined]' : '[object Null]'
    }
    return Object.prototype.toString.call(value)
}

export function isObject(value) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function')
}

export function isFunction(value) {
    if (!isObject(value)) {
        return false
    }

    const tag = getTag(value);
    return tag === '[object Function]' || tag === '[object AsyncFunction]' ||
        tag === '[object GeneratorFunction]' || tag === '[object Proxy]'
}

export function findDOMElements(target) {
    if (isReactRefObj(target)) {
        return target.current;
    }
    if (isFunction(target)) {
        return target();
    }
    if (typeof target === 'string' && canUseDOM) {
        let selection = document.querySelectorAll(target);
        if (!selection.length) {
            selection = document.querySelectorAll(`#${target}`);
        }
        if (!selection.length) {
            throw new Error(
                `The target '${target}' could not be identified in the dom, tip: check spelling`
            );
        }
        return selection;
    }
    return target;
}

export function isArrayOrNodeList(els) {
    if (els === null) {
        return false;
    }
    return Array.isArray(els) || (canUseDOM && typeof els.length === 'number');
}

export function getTarget(target, allElements) {
    const els = findDOMElements(target);
    if (allElements) {
        if (isArrayOrNodeList(els)) {
            return els;
        }
        if (els === null) {
            return [];
        }
        return [els];
    } else {
        if (isArrayOrNodeList(els)) {
            return els[0];
        }
        return els;
    }
}