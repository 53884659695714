import { Helmet } from "react-helmet";
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, NavItem, Collapse } from 'reactstrap';
import Sidebar from './Sidebar.js';
import { useState, useEffect } from 'react';
import { Outlet, useLocation, NavLink } from "react-router-dom";
import { MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster } from '@vidstack/react';
import ReactPlayer from 'react-player'
import VideoItem from './VideoItem';
import { Suspense } from 'react';
import { useLoaderData,Await } from "react-router-dom"; 
import 'vidstack/styles/defaults.css';
import './VideoDetail.css';
import './PlayVideo.css';
import { useCookies } from 'react-cookie';

export default function VideoDetail() {
    const [cookies, setCookie] = useCookies(['language']);
    const [language, setlanguage] = useState(cookies.language);

    useEffect(() => {
        const newLanguage = cookies.language;
        if (newLanguage !== language) {
            window.location.reload();
        }

    }, [cookies.language]);

    const { t } = useTranslation();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [activeYear, setactiveYear] = useState('');
    const [isHorizontal, setisHorizontal] = useState(false);

 

    const videoRef = useRef();
    const listRef = useRef();


    const [listHeight, setlistHeight] = useState(0);

    function throttle(callback, delay = 200) {
        let timer;
        return (...args) => {
            if (timer) return
            timer = setTimeout(() => {
                callback(...args);
                timer = null;
            }, delay)
        }
    }
    function onHandleResize() {
        if (window.innerWidth >= 992) {
            if (!videoRef.current) {
                return;
            }
            
            const height = videoRef.current.offsetHeight;
            setlistHeight(height);
            setisHorizontal(false);
        }
        else {
            setlistHeight('auto');
            setisHorizontal(true);
            
        }
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }

    }

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
        
        if (window.innerWidth >= 992) {
            const height = videoRef.current.offsetHeight;
            setlistHeight(height);
            setisHorizontal(false);
        }
        else {
            setlistHeight('auto');
            setisHorizontal(true);
        }

        window.addEventListener('resize', throttle(onHandleResize));


    }, [])

    function onHandleLeftChange() {
        const width = listRef.current.offsetWidth;
        listRef.current.scrollBy(-width, 0);
    }

    function onHandleRightChange() {
        const width = listRef.current.offsetWidth;
        listRef.current.scrollBy(width, 0);
    }

    function onHandleUpChange() {
        const height = listRef.current.offsetHeight;
        listRef.current.scrollBy(0, -height);
    }


    function onHandleDownChange() {
        const height = listRef.current.offsetHeight;
        listRef.current.scrollBy(0, height);
    }

    const data = useLoaderData();
    return (
        <>
            <Helmet>
                <title>{t("Review")}</title>
            </Helmet>
            <div className="video_detail">
                <Container>
                    <div className="video_container" >
                        { /*
                        <div color="primary" className="video_toggle" onClick={toggle} style={{ marginBottom: '1rem' }}>
                            <div>
                                <Suspense
                                    fallback={<p>Loading...</p>}
                                >
                                    <Await
                                        resolve={data.Result}
                                    >

                                        {
                                            (result) => {

                                                const { yearPlayBacks } = result;
                                                const activeyearPlayback = yearPlayBacks.filter(y => location.pathname.includes(y.year));

                                                return (
                                                    <>
                                                        <span>{activeyearPlayback[0].year}</span>&nbsp;
                                                        {
                                                            isOpen ? (<i className="bi bi-chevron-down"></i>) : (<i className="bi bi-chevron-right"></i>)
                                                        }
                                                    </>)
                                            }
                                        }


                                    </Await>

                                </Suspense>

                            </div>
                        </div>
                        <Collapse isOpen={isOpen}>
                        <Sidebar>
                                <Suspense
                                    fallback={<p>Loading...</p>}
                                >
                                    <Await
                                        resolve={data.Result}
                                    >
                                        {
                                            (result) => {
                                                const { yearPlayBacks } = result;
                                                if (yearPlayBacks) {
                                                    return yearPlayBacks.map((yp) => (
                                                        <NavItem key={ yp.year} className={location.pathname.toLowerCase().startsWith(`/review/${yp.year}`) ? "navitemactive" : ''}>
                                                            <NavLink to={`/review/${yp.year}`} className={({ isActive, isPending }) =>
                                                                isPending ? "pending" : isActive ? "sideactive" : ""
                                                            }>{yp.year}</NavLink>
                                                        </NavItem>
                                                    ))
                                                }
                                                return false

                                            }
                                        }


                                    </Await>

                                </Suspense>
                                {/*
                            <NavItem className={location.pathname.toLowerCase().startsWith('/review/2022') ? "navitemactive" : ''}>
                                <NavLink to="/review/2022" className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "sideactive" : ""
                                }>2022</NavLink>
                            </NavItem>
                            <NavItem className={location.pathname.toLowerCase().startsWith('/review/2021') ? "navitemactive" : ''}>
                                <NavLink to="/review/2021" className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "sideactive" : ""
                                }>2021</NavLink>
                            </NavItem>
                            <NavItem className={location.pathname.toLowerCase().startsWith('/review/2020') ? "navitemactive" : ''}>
                                <NavLink to="/review/2020" className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "sideactive" : ""
                                }>2020</NavLink>
                            </NavItem>
                            <NavItem className={location.pathname.toLowerCase().startsWith('/review/2019') ? "navitemactive" : ''}>
                                <NavLink to="/review/2019" className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "sideactive" : ""
                                }>2019</NavLink>
                            </NavItem>
                            
                            </Sidebar>
                        </Collapse> */}
                        <div className="video_wrapper">
                            <div className="video_player" >
                                <div ref={videoRef}>
                                    <Suspense
                                        fallback={<p>{t("Loading...")}</p>}
                                    >
                                        <Await
                                            resolve={ data.Result}
                                        >
                                            {
                                                (result) => {
                                                    const { videoInfo } = result;
                                                    console.log(videoInfo);
                                                    return (
                                                        <>
                                                            <video controls preload="auto" poster={videoInfo.videoPosterUrl} src={videoInfo.videoVideoUrl}></video>
                                                            <div className="video_desc">
                                                                <div className="video_title"
                                                                >
                                                                    {videoInfo.videoTitle }
                                                                </div>
                                                                <div className="video_author">
                                                                    {videoInfo.videoSpeaker.speakerName }
                                                                </div>
                                                                <div className="video_occupation">
                                                                    {videoInfo.videoSpeaker.speakerInstitution}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            }
                                        </Await>

                                    </Suspense>

                                    
                                </div>
                            </div>

                            <div className="v_list">
                                <ul className="video_slist" style={{ height: listHeight }} ref={listRef}>
                                    <Suspense
                                        fallback={<p>{t("Loading...")}</p>}
                                    >
                                        <Await
                                            resolve={data.Result}
                                        >
                                            {
                                                (result) => {
                                                    
                                                    const { videos } = result;

                                                    return (
                                                        videos.map(
                                                            video=>(
                                                                <VideoItem key={video.videoId}  year={video.videoYear} to={`/review/${video.videoYear}/${ video.videoId }`} alt={video.videoTitle} src={video.videoPosterUrl} title={ video.videoTitle} />
                                                            )
                                                        )
                                                    )
                                                }
                                            }


                                        </Await>
                                    </Suspense>
                                    {/*
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Single-cell 3D Genome Determination with Kilobase Resolution Reveals Transcription Elongation Loops" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Single-cell 3D Genome Determination with Kilobase Resolution Reveals Transcription Elongation Loops" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                <VideoItem onHandleVideoClick={onHandleVideoPlay} year='2023' id='1' alt="abc" src="/images/poster/20221019111309595390.jpg" title="Understanding Brain Cell Type Diversity" />
                                */ }
                                </ul>
                                {

                                    isHorizontal ? (<><div className="left_arrow" onClick={onHandleLeftChange}>
                                        <i className="bi bi-chevron-left"></i>
                                    </div>
                                    <div className="right_arrow" onClick={onHandleRightChange}>
                                        <i className="bi bi-chevron-right"></i>
                                        </div>
                                    </>
                                    ) : (<><div className="v_up_arrow" onClick={onHandleUpChange}>
                                        <i className="bi bi-chevron-up"></i>
                                    </div>
                                        <div className="v_down_arrow" onClick={onHandleDownChange}>
                                            <i className="bi bi-chevron-down"></i>
                                        </div>
                                    </>
                                    )
                                }
                                    
                            </div>
                            

                        </div>
                    </div>

                </Container>
            </div>
        </>
    )
}