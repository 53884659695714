import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { Container, NavItem, NavLink } from 'reactstrap';
import { useLoaderData, Await,useAsyncValue } from "react-router-dom";
import Sidebar from './Sidebar.js';
import './SpeakerDetail.css';



export default function SpeakerDetail() {

    const { t } = useTranslation();

    function SpeakerInfo() {
        const result = useAsyncValue();
        const { speakerInfo } = result; 
        return (
            <div className="sintro_container">
                <div className="speaker_avatar">
                    <div className="sd_crop">
                        <img src={speakerInfo.speakerImageUrl} alt={speakerInfo.speakerName} />
                    </div>
                    <div className="sd_title">
                        <div>{speakerInfo.speakerName}</div>
                        <div>{speakerInfo.speakerTitle}</div>
                        <div>{speakerInfo.speakerInstitution}</div>
                    </div>
                </div>
                
                    <div className="s_introduction" dangerouslySetInnerHTML={{ __html: speakerInfo.speakerDescription }}></div>
                
            </div>)
        
    }

    const data = useLoaderData();
    return (
        <Suspense
            fallback={<p>{t("Loading...")}</p> }
        >
        <Await
            resolve={data.Result}
        >
                <SpeakerInfo />
                {
                    /*
                    return (<div className="sintro_container">
                        <div className="speaker_avatar">
                            <div className="sd_crop">
                                <img src={speakerInfo.speakerImageUrl} alt={speakerInfo.speakerName} />
                            </div>
                            <div className="sd_title">
                                <div>{speakerInfo.speakerName}</div>
                                <div>{speakerInfo.speakerTitle}</div>
                                <div>{speakerInfo.speakerInstitution}</div>
                            </div>
                        </div>
                        <div className="s_introduction">
                            {speakerInfo.speakerDescription}
                        </div>
                </div>)
                    */
                } 
            
            </Await>
        </Suspense>
    )
}