import './HCtitle.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import "./Sponsors.css";

class Sponsors extends Component {


    render(){
        return (
            <ul className="sponsors_container">
                {this.props.children}
            </ul>
        );
    }
}

export default withTranslation()(Sponsors);