import './HCtitle.css';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './Sponsor.css';
class Sponsor extends Component {


    render() {
        const { title, src,website } = this.props;
        return (
            <li>
                <Link className="sponsor_img_container" to={website }>
                    <img alt={title} src={ src} />
                </Link>
            </li>
        );
    }
}

export default withTranslation()(Sponsor);