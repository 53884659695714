import './EmailVerify.css';
import { useLoaderData } from "react-router-dom";
import { useTranslation } from 'react-i18next';
export default function EmailVerify() {
    const [t, i18n] = useTranslation();
    const result = useLoaderData();
    
    
    return (<div className="mail_header">
        <div className="email_confirm">
            {
                result ? (
                    <>
                    <p>
                {t("Your Email Is Verified")}
            </p>
            <p>
                {
                    i18n.language.startsWith('zh') ? (<span>您已成功注册2023年单细胞组学国际研讨会</span>) : (<span>You have successfully register for the <strong>Singel Cell Omics Beijing 2023</strong></span>)
                }
            </p>
                    </>)
            :(
                        <p>
                            {t("Sorry, this link is invalid!") }
            </p>
            )
            }
        </div>
        
    </div>)
}