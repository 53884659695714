import './HCtitle.css';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChevronRight } from 'react-bootstrap-icons';

class HCtitle extends Component {
    static displayName = HCtitle.name;



    render() {

        const { t } = this.props;



        return (
            <>
                <div className="hctitle_nav">
                    <div className="hctitle">
                        <Link to="exhibition/"  reloadDocument target="_blank">
                        <span>
                            {t("exhibition")}
                        </span>
                        </Link>
                        <ChevronRight className="hctitle_arrow" />
                    </div>
                    <Link to="/exhibition"  reloadDocument target="_blank" className="t_learnmore">{t("learnmore")}</Link>
                </div>

            </>
        );
    }
}

export default withTranslation()(HCtitle);

