import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { useRef, useState, useEffect, Suspense } from 'react';
import { Container, NavItem} from 'reactstrap';
import { Outlet,useLocation,NavLink,useLoaderData ,Await} from "react-router-dom";
import { Helmet } from "react-helmet";
import Sidebar from './Sidebar.js';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './Speakers.css';
import Speaker from './Speaker';
import { throttle } from "./utilis"

import './Sidebar.css';

export default function SpeakersYear(args) {
   

    const data = useLoaderData();
    
    
    

    
    
   
    const location = useLocation();
    const { t } = useTranslation();
    
    

    return (
        <>
            <Helmet>
                <title>{t("speakers")}</title>
            </Helmet>
            <div className="speakers">
                <Container>
                    <div className="speakers_container">
                        <Sidebar >
                            <Suspense fallback={<p>Loading...</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (!result) {
                                                return false;
                                            }
                                            const { ypmap } = result;
                                           return ypmap.map(yp => (
                                                <NavItem key={ yp.year } className={location.pathname.toLowerCase().startsWith(`/speakers/${yp.year}`) ? "navitemactive" : ''}>
                                                    <NavLink to={`/speakers/${yp.year}/${yp.speaker}` } className={({ isActive, isPending }) =>
                                                        isPending ? "pending" : isActive ? "sideactive" : ""
                                                    }>{yp.year }
                                                    </NavLink>
                                                </NavItem>
                                            ))
                                        }
                                    }
                                </Await>
                            </Suspense>
                          
                        </Sidebar>


                        <Outlet />

                        

                    </div>
                </Container>

            </div>
        </>
    )
}

export function Speakers() {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const [activeSpeaker, setActiveSpeaker] = useState("");
    const containerRef = useRef();
    const data = useLoaderData();

    function onHandleSpeakerCheck(name) {
        setActiveSpeaker(name);
    }
    function onHandlePreChange() {
        const containerHeight = containerRef.current.offsetHeight;
        containerRef.current.scrollBy(0, -containerHeight);
    }

    function onHandleNextChange() {
        const containerHeight = containerRef.current.offsetHeight;
        containerRef.current.scrollBy(0, containerHeight);
    }

    function onHandleSpeakerChange(e) {
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }

        setActiveSpeaker(e.name);
    }
    function onHandleResize() {
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    }


    

    const toggle = () => setIsOpen(!isOpen);

    useEffect(() => {
        window.addEventListener('resize', throttle(onHandleResize));
        if (window.innerWidth < 768) {
            setIsOpen(false);
        }
        else {
            setIsOpen(true);
        }
    }, []);

   
    

    return (
        <div className="s_speakers">
            <div className="" >
                <div color="primary" className="speaker_toggle" onClick={toggle} style={{ marginBottom: '1rem' }}>
                    <div>
                        <span>{activeSpeaker}</span> &nbsp;
                        {
                            isOpen ? (<i className="bi bi-chevron-down"></i>) : (<i className="bi bi-chevron-right"></i>)
                        }

                    </div>
                </div>

                <Collapse className="speaker_collapse" isOpen={isOpen} >
                    <div className="speakers_list">
                        <ul className="speaker_container" ref={containerRef}>
                            <Suspense fallback={<p>{t("Loading...")}</p>} >
                                <Await resolve={data.Result}>
                                    {
                                        (result) => {
                                            if (!result) {
                                                return false;
                                            }
                                            const { speakers } = result
                                            return speakers.map(speaker => (
                                                <Speaker onHandleSpeakerCheck={onHandleSpeakerCheck} key={speaker.speakerId} onHandleClick={onHandleSpeakerChange} alt={speaker.speakerName} src={speaker.speakerImageUrl} name={speaker.speakerName} institution={speaker.speakerInstitution} year={speaker.speakerYear} id={speaker.speakerId}></Speaker>

                                            ))
                                        }

                                    }
                                </Await>
                            </Suspense>

                           
                        </ul>

                        <div className="top_arrow" onClick={onHandlePreChange}>
                            <i className="bi bi-chevron-up"></i>
                        </div>
                        <div className="down_arrow" onClick={onHandleNextChange}>
                            <i className="bi bi-chevron-down"></i>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="speaker_detail">
                <Outlet />
            </div>
        </div>
    );
}