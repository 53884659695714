import React, { useRef, useState } from 'react';
import "./Chairman.css"
import classNames from 'classnames';

export default function Chairman({ children, name, src, index, html }) {

    const [hoverStyle, sethoverstyle] = useState({});
    const [isHover, setisHover] = useState(false);
    const chairRef = useRef();
    const hoverRef = useRef();

    function OnHanleMouseEnter(e) {

        if (window.innerWidth < 768) {
            return;
        }

        const chairPositionLeft = chairRef.current.offsetLeft;
        const chairPositionTop = chairRef.current.offsetTop;

        e.preventDefault();



        if (parseInt(index) === 3) {
            sethoverstyle({ right: 0, top: chairPositionTop });
        } else {
            sethoverstyle({ left: chairPositionLeft, top: chairPositionTop });
        }

        setisHover(true);
    }

    function onHandleMouseOut() {
        setisHover(false);
    }


    let hoverClasses = classNames(
        'chair_hover',
        isHover ? '' : 'nohover'
    );

    let chairNameClasses = classNames(
        'chair_name',
        isHover ? 'nohover' : ''
    )

    let imgContainerClasses = classNames(
        'chair_img_container',
        isHover ? 'nohover' : ''
    )

    return (
        <div className="chair_wrapper" ref={chairRef} >
            <div className="chair_item_container" >
                <div className={imgContainerClasses} onMouseEnter={OnHanleMouseEnter} >
                    <img alt={name} src={src} />
                </div>
                <div className="chair_profile">
                    <div className={chairNameClasses}>
                        {name}
                    </div>
                    <div className="chair_desc" dangerouslySetInnerHTML={html}>
                        
                    </div>
                </div>
            </div>

            <div style={hoverStyle} className={hoverClasses} ref={hoverRef} onMouseLeave={onHandleMouseOut}>
                <div className="chair_img_container">
                    <img alt={name} src={src} />
                </div>
                <div className="chair_profile">
                    <div className="chair_name">
                        {name}
                    </div>
                    <div className="chair_desc" dangerouslySetInnerHTML={html}>
                        
                    </div>


                </div>

            </div>




        </div>
    )
}
export function ChairItem({ name, src, html }) {
    //console.log(name);
    return (
        <div className="about_chair">
            <div className="chair_img_container">
                <img alt={name} src={src} />
            </div>
            <div className="about_chair_profile">
                <div className="chair_name">
                    {name}
                </div>
                <div className="chair_desc" dangerouslySetInnerHTML={html}>
                    
                </div>
            </div>
        </div>
    )
}



