import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Collapse, NavLink, CloseButton } from 'reactstrap'; 
import { Reference } from 'react-popper'; 
import { createRoot } from 'react-dom/client';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const defaultProps = {
    subItems:[
    ]
};

class Submenu extends Component {
    static displayName = Submenu.name;
    constructor(props) {
        super(props);
       // this.state = { isCollapsed: false };
        this.onClick = this.onClick.bind(this);
        this.onHandleNavActive = this.onHandleNavActive.bind(this);        
    }

    onClick() {
        //this.setState({ isCollapsed: !this.state.isCollapsed });
       
        this.props.onClick();
    }

    onHandleNavActive() {
        this.props.onHandleNavActive();
    }

    render() {
        const { ribbonMenu, innerRef, className, isOpen, subItems, ...attributes } = this.props;
        const classes = classNames(
            className,
            'w-100'
          //  this.state.isCollapsed? 'show': false
        );

        
        const items = subItems.map((item) => {
            return (
                <NavLink key={ item.content} tag={Link} to={item.url} className="text-dark">{item.content}</NavLink>
            )
        });

        return (
            
            <Collapse isOpen={isOpen} className={classes}>
                    <div className="justify-content-start d-flex w-100 flex-column submenu">
                        <div className="bg-close">
                            <Container className="d-flex flex-row-reverse">
                                <CloseButton onClick={this.onClick} />
                            </Container>
                        </div>

                    <div className="submenu-title container" onClick={this.onHandleNavActive }>

                        {ribbonMenu}
                            {/*
                        <NavLink tag={Link} to="/people" className='text-dark'>People</NavLink>
                        <NavLink tag={Link} className="text-dark" to="/people/sunneyxie">Xiaoliang Sunney Xie</NavLink>
                        <NavLink tag={Link} className="text-dark" to="/people/groupMembers">Group Members</NavLink>
                        <NavLink tag={Link} className="text-dark" to="/people/formerMembers">Former Group Members</NavLink>
                        */ }
                        </div>
                    </div>
                </Collapse>
               
        );
    }
}

Submenu.defaultProps = defaultProps;
export default Submenu;