/// <reference path="ribbon.js" />
import React, { Component } from 'react';
import { Collapse, NavItem,  Container} from 'reactstrap';
import { Link,NavLink } from 'react-router-dom';
import { Navbar } from './Navbar';

import  LoginMenu  from './api-authorization/LoginMenu';
import './NavMenu.css';
import Submenu from './Submenu';
import { withTranslation } from 'react-i18next';



//import { Dropdown } from 'bootstrap';



class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.toggleResearch = this.toggleResearch.bind(this);
        this.togglePeople = this.togglePeople.bind(this);
        this.handleActiveChange = this.handleActiveChange.bind(this);
        this.onHandleCloseSubMenu = this.onHandleCloseSubMenu.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.toggleRibbon = this.toggleRibbon.bind(this);
        this.state = {
            collapsed: true,
            isResearchDpOpen: false,
            isPeopleDpOpen: false,
            activeNav: '',
            isSubMenuOpen: false,
            subItems: [],
            subMenu: null,
            ribbonMenu: null,
            ribbonName:''
        };
        this.navRef = React.createRef();
      
        //this.menuRef = React.createRef();
    }

    componentDidMount() {
      //  this.state.FocusElements.previousNode = this.navRef.current;
        // this.state.FocusElements.currentNode = this.navRef.current;
        this.setState({ activeNav:window.location.pathname });
    }


    onHandleCloseSubMenu() {
        this.setState({ isSubMenuOpen: !this.state.isSubMenuOpen, ribbonName: '' });
    }

    handleActiveChange(e) {
        const pathName = window.location.pathname;
        if (pathName !== this.state.activeNav && this.state.isSubMenuOpen)
            this.setState({ activeNav: pathName, isSubMenuOpen: false, ribbonName: '' });
        else
            this.setState({ activeNav: pathName });


        !this.state.collapsed && this.setState({
            collapsed: true
        });
        
    }

    toggleNavbar(e) {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    toggleResearch() {
        this.setState({
            isResearchDpOpen: !this.state.isResearchDpOpen
        });
    }

    togglePeople() {
        this.setState({
            isPeopleDpOpen: !this.state.isPeopleDpOpen
        });
    }

    toggleSubMenu(e) {
        
        this.setState({ isSubMenuOpen: !this.state.isSubMenuOpen, subItems: e });       
    }


    toggleRibbon(e) {
        
       // e is a object of { ribbonMenu: this.ribbonMenu, ribbonName: ribbonName }
       
        if (!this.state.isSubMenuOpen)
            this.setState({ isSubMenuOpen: true, ribbonMenu: e.ribbonMenu.props.children, ribbonName: e.ribbonName });
        else
            this.setState({ isSubMenuOpen: false, ribbonName: '' });
        
    }

    render() {
        
        const { t,reviews,speakers } = this.props;

        return (
            <>
            <header>
                <div className="nav-logo">
                    <Container>
                        <NavLink tag={ Link } to="/">
                            <img src="/logo.svg" alt="logo" />
                        </NavLink>
                        
                    </Container>
                </div>
                
                <Navbar className="navbar-expand-sm justify-content-start navbar-toggleable-sm ng-white flex-column align-items-start" light >
                    <Container >
                            <button onClick={this.toggleNavbar} className="navbar-toggler" type="button" data-mdb-toggle="collapse"
                                data-mdb-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                {this.state.collapsed ?<i className="bi bi-list"></i>:
                                    <i className="bi bi-x"></i>
                                }
                                
                            </button>
                    </Container>
                    <Collapse className="" isOpen={!this.state.collapsed} navbar >
                        <Container >
                                <ul className="navbar-nav flex-grow" onClick={this.handleActiveChange}>
                                    <NavItem  >
                                        <NavLink  className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : "text-dark nav-link"
                                        }   to="/">{t("home")}</NavLink>
                                </NavItem>
                                <NavItem>
                                        <NavLink className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : "text-dark nav-link"
                                        } to="/aboutus">{t("aboutus")}</NavLink>
                                </NavItem>
                                <NavItem>
                                        <NavLink className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : "text-dark nav-link"
                                        } to="/agenda">{t("agenda")}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : window.location.pathname.toLowerCase().startsWith("/speakers") ? "active text-dark nav-link" : "text-dark nav-link"
                                        } to={`/speakers/${speakers.year}/${speakers.speaker}` }>{t("speakers")}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : window.location.pathname.toLowerCase().startsWith("/review") ? "active text-dark nav-link" : "text-dark nav-link"
                                        } to={`/review/${reviews[0].year}` }>{t("review")}</NavLink>
                                    </NavItem>
                                    <NavItem  >
                                        
                                        <NavLink  reloadDocument target="_blank" className={({ isActive, isPending }) =>
                                            isPending ? "text-dark nav-link" : isActive ? "active text-dark nav-link" : "text-dark nav-link"
                                        } to="/exhibition">{t("Exhibition")}</NavLink>
                                    </NavItem>
                                {/*
                            <Dropdown nav active={this.state.activeNav.startsWith('/people')} isOpen={this.state.isPeopleDpOpen} toggle={this.togglePeople}>
                                <DropdownToggle className="text-dark" nav caret>
                                    People
                                </DropdownToggle>
                                <DropdownMenu>
                                    <NavLink tag={Link} className="text-dark" to="/people/sunneyxie">Xiaoliang Sunney Xie</NavLink>

                                    <NavLink tag={Link} className="text-dark" to="/people/groupMembers">Group Members</NavLink>

                                    <NavLink tag={Link} className="text-dark" to="/people/formerMembers">Former Group Members</NavLink>

                                </DropdownMenu>
                            </Dropdown>
                           

                                <NavItem>
                                    <NewNavLink active={this.state.activeNav.toLowerCase().startsWith('/research')} tag={Link} className="text-dark dropdown-toggle" to="/" onClick={this.toggleSubMenu} subItems={[{ url: 'a', content: 'b' }, { url: 'b', content: 'c' }, {url:'c',content:'d'} ]}>Research</NewNavLink>
                                </NavItem>
                                 */ }
                                    {/*
                                <Ribbon isOpened={this.state.ribbonName === 'Speakers'} isOpen={false} active={this.state.activeNav.toLowerCase().startsWith('/speakers')} toggle={this.toggleRibbon} nav tag='li'>
                                    <RibbonToggle className="text-dark nav-link" nav caret ribbonName='Speakers' isOpen={this.state.ribbonName === 'Speakers'}>
                                        {t("speakers")}
                                    </RibbonToggle>
                                    <RibbonMenu >
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/speakers/2023')} to="/speakers/2023/1">2023</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/speakers/2022')} to="/speakers/2022/1">2022</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/speakers/2021')} to="/speakers/2021/1">2021</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/speakers/2020')} to="/speakers/2020/1">2020</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/speakers/2019')} to="/speakers/2019/1">2019</NavLink>
                                    </RibbonMenu>
                                    
                                </Ribbon>
                                <Ribbon isOpened={this.state.ribbonName === 'Review'} isOpen={false} active={this.state.activeNav.toLowerCase().startsWith('/review')} toggle={this.toggleRibbon} nav tag='li'>
                                    <RibbonToggle className="text-dark nav-link" nav caret ribbonName='Review' isOpen={this.state.ribbonName === 'Review'}>
                                        {t("review")}
                                    </RibbonToggle>
                                    <RibbonMenu >                            
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/review/2022')} to="/review/2022">2022</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/review/2021')} to="/review/2021">2021</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/review/2020')} to="/review/2020">2020</NavLink>
                                            <NavLink tag={Link} className="text-dark nav-link" active={this.state.activeNav.toLowerCase().startsWith('/review/2019')} to="/review/2019">2019</NavLink>
                                    </RibbonMenu>

                                </Ribbon>
                                */ }
                                {/*
                            <Dropdown nav  isOpen={this.state.isResearchDpOpen} toggle={this.toggleResearch}>
                                <DropdownNewToggle active={this.state.activeNav.toLowerCase().startsWith('/research')}  className="text-dark nav-item"  caret>                                   
                                        Research
                                </DropdownNewToggle>
                                <DropdownMenu>                                    
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/overview')} to="/research/overview">Overview</NavLink>
                                   
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/scgenomics')} to="/research/scGenomics">Single Cell Genomics</NavLink>

                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/scenzymology')} to="/research/scEnzymology">Single Molecule Enzymology</NavLink>
                                    
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/scimaging')} to="/research/scImaging">Single Molecule Imaging</NavLink>
                                    
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/geregulation')} to="/research/geRegulation">Gene Expression and Regulation</NavLink>
                                    
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/crsmicroscopy')} to="/research/crsMicroscopy">CRS Microscopy</NavLink>
                                   
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/research/covid19')} to="/research/covid19">COVID-19</NavLink>
                                    
                                </DropdownMenu>
                            </Dropdown>
                                
                            <Dropdown nav  isOpen={this.state.isPeopleDpOpen} toggle={this.togglePeople}>
                                <DropdownNewToggle split active={this.state.activeNav.toLowerCase().startsWith('/people')} className="text-dark" nav caret>People</DropdownNewToggle>
                                
                                <DropdownMenu>
                                
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/people/sunneyxie')} to="/people/sunneyxie">Xiaoliang Sunney Xie</NavLink>
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/people/groupmembers')} to="/people/groupMembers">Group Members</NavLink>
                                    <NavLink tag={Link} className="text-dark" active={this.state.activeNav.toLowerCase().startsWith('/people/formermembers')} to="/people/formerMembers">Former Group Members</NavLink>
                                </DropdownMenu>
                                
                            </Dropdown>

                          */ }
                                
                               
                                    <LoginMenu>
                                    </LoginMenu>
                            
                            
                            </ul>
                        </Container>
                        {/* expand menu
                        <Collapse className="d-sm-flex flex-sm-column" isOpen={!this.state.collapsed} navbar>
                            <Navbar className="ng-white">
                                <ul className="navbar-nav flex-grow justify-content-start flex-sm-column" onClick={this.handleActiveChange}>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/people">People</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/people/sunneyxie">Xiaoliang Sunney Xie</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/people/groupMembers">Group Members</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/people/formerMembers">Former Group Members</NavLink>
                                    </NavItem>
                                </ul>
                            </Navbar>
                        </Collapse>
                        */}
                    </Collapse>

                    <Submenu className='second' onClick={this.onHandleCloseSubMenu} isOpen={this.state.isSubMenuOpen}  ribbonMenu={this.state.ribbonMenu} onHandleNavActive={this.handleActiveChange} />

                </Navbar>
                
               
            </header>
              { this.props.children }
              </>
              
        );
}

}
export default withTranslation()(NavMenu);





