import React, { Component } from 'react';
import {
    createBrowserRouter,
    RouterProvider, Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { useTranslation } from 'react-i18next';
import './custom.css';
import { useCookies } from 'react-cookie';


export default function App() {
   // const { i18n } = useTranslation();
    const [cookies, setCookie] = useCookies(['language']);
    if (!cookies.language) {
        const language = navigator.language;
        if (language.startsWith('zh')) {
            setCookie('language', 'zh-CN', { path: '/' });
        }
        else {
            setCookie('language', 'en-US', { path: '/' });
            
        }
    }
    

    
    

    const router = createBrowserRouter(
        [
            ...AppRoutes
        ]
  
        );
        return (
                <RouterProvider router={ router } />
        );
    
}
