import React, { createRef, Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import './HomeSpeaker.css';
import { Transition } from 'react-transition-group';
import { NavLink } from "react-router-dom";

class HomeSpeaker extends Component {
    static displayName = HomeSpeaker.name;

    constructor(props) {
        super(props);
        this.to = this.props.to;
        this.state = {
            isHover: false,styles: {} }
        this.speakerRef = React.createRef();
    }


    onHandleMouseOver = (e) => {
        const { width, height, count, rowGap } = this.props.onHandleHover();
        const windowWidth = window.innerWidth;
        let spaceWidth = 0;
        let spaceHeight = 0;
        let containerWidth = 0;
        if (windowWidth > 1200) {
            let rows = Math.ceil(count / 6);

            let rowHeight = (height - (rows - 1) * rowGap) / rows;

            if (this.props.index % 6 === 1) {
                spaceWidth = 0;
            }
            else if (this.props.index % 6 === 0) {
                spaceWidth = undefined;
            }
            else {
                spaceWidth = width / 6 * (this.props.index%6-1);
            }
            containerWidth = width / 3;

            let currentRow = Math.ceil(this.props.index / 6);
            spaceHeight = (currentRow - 1) * (rowHeight + rowGap);

        }
        else if (windowWidth > 992) {
            let rows = Math.ceil(count / 5);
 
            let rowHeight = (height - (rows - 1) * rowGap) / rows;

            if (this.props.index % 5 === 1) {
                spaceWidth = 0;
            }
            else if (this.props.index % 5 === 0) {
                spaceWidth = undefined;
            }
            else {
                spaceWidth = width / 5 * (this.props.index % 5 - 1);
            }
            containerWidth = width / 2.5;
            let currentRow = Math.ceil(this.props.index / 5);
            spaceHeight = (currentRow - 1) * (rowHeight + rowGap);
        }
        else if (windowWidth > 768) {
            let rows = Math.ceil(count / 4);
      
            let rowHeight = (height - (rows - 1) * rowGap) / rows;
       
            if (this.props.index % 4 === 1) {
                spaceWidth = 0;
            }
            else if (this.props.index % 4 === 0) {
                spaceWidth = undefined;
            }
            else {
                spaceWidth = width / 4 * (this.props.index % 4 - 1);
            }
            containerWidth = width / 1.5;
            let currentRow = Math.ceil(this.props.index / 4);
            spaceHeight = (currentRow - 1) * (rowHeight + rowGap);
        }
        else {
            let rows = Math.ceil(count / 3);
            let rowHeight = (height - (rows - 1) * rowGap) / rows;
            if (this.props.index % 3 === 1) {
                spaceWidth = 0;
            }
            else if (this.props.index % 3 === 0) {
                spaceWidth = undefined;
            }
            else {
                spaceWidth = width / 3 * (this.props.index % 3 - 1);
            }
            containerWidth = width / 1;
            let currentRow = Math.ceil(this.props.index / 3);
            spaceHeight = (currentRow - 1) * (rowHeight + rowGap);
        }

        let popoutStyles = {};

        if (spaceWidth !== undefined) {
            popoutStyles = {
                left: spaceWidth,
                top: spaceHeight,
                width: containerWidth
            };
        }
        else {
            popoutStyles = {
                top: spaceHeight,
                width: containerWidth,
                right:0
            };
        }
            



        this.setState({ isHover: true, styles: popoutStyles });
    };

    onHandleMouseOut = (e) => {
        this.setState({ isHover: false });
    }

    onHandleArticleMouseOver = (e) => {
        e.stopPropagation();
    }

    popout(alt, src, name, institution, index, to) {
        return (
            <div className="popout vrhc" data-index={index} style={this.state.styles} >
                <div className="h_crop_speaker">
                    <img alt={alt} src={src} />
                </div>
                <div className="h_speaker_descrip" >
                    <section className="h_speaker_name clamp_ecllipse">
                        {name}
                    </section>
                    <section className="clamp_ecllipse h_instit">
                        {institution}
                    </section>
                    <section className="hp_desc">{this.props.children}</section>
                    <section>
                        <div className="readmore">
                            <Link to={this.to} >Read More&nbsp;<i className="bi bi-chevron-right"></i></Link>
                        </div>
                    </section>
                </div>
            </div>
        );
    }




    render() {

        const hspeakerclass = classNames(
            'h_speaker',
            this.state.isHover ? 'h_speaker_hov' : 'h_speaker_nhov'
        );

        const noneclass = classNames(
            this.state.isHover ? 'none' : false
        );

        const descriStyle = this.state.isHover ? { width: '100%' } : {};

        const { name, title, alt, src, institution, index, id, to } = this.props;
        return (
            <li className="h_speaker h_speaker_nhov" onMouseLeave={this.onHandleMouseOut}>
                {this.state.isHover ? this.popout(alt, src, name, institution, index,id, to) : false}
                <NavLink className={noneclass} to={to}>
                    <div className="h_crop_speaker" onMouseEnter={this.onHandleMouseOver} >
                        <img alt={alt} src={src} />
                    </div>
                    <div className="h_speaker_descrip" >
                        <section className="h_center h_speaker_name clamp_ecllipse">
                            {name}
                        </section>
                        <section className="h_center clamp_ecllipse h_instit">
                            {institution}
                        </section>
                       
                    </div>
                </NavLink>
            </li>
        )
    }
}

export default withTranslation()(HomeSpeaker)

